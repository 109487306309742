import { serverClient } from '../serverClient'
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import { Region, RegionPayload } from '../../interfaces/Region'
import { createApiError } from '../createApiError'
import { oc } from 'ts-optchain'
import { Pagination } from '../../interfaces/Pagination'
import { PaginatedResult } from '../../interfaces/PaginatedResult'

/**
 * ----------------------------------------------------------------------------
 * Regiões
 * ----------------------------------------------------------------------------
 */

export async function fetchRegions(
  options: {
    filters?: RegionFilters
    orderBy?: string
    pagination?: Pagination
  } = {}
): Promise<PaginatedResult<Region>> {
  try {
    const filters = {
      Descricao: oc(options).filters.description()
    }

    const params = {
      page: oc(options).pagination.current(),
      pageSize: oc(options).pagination.pageSize(),
      include: ['Cidades'],
      ...filters,
      orderBy: [options.orderBy]
    }

    const response = await serverClient.get<IPaginatedResponse<Region>>(
      `/Regiao`,
      { params }
    )

    return {
      items: response.data.result,
      pagination: {
        current: response.data.page,
        pageSize: response.data.pageItemCount,
        total: response.data.totalItemCount
      }
    }
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchRegion(id: number) {
  try {
    const params = {
      Include: ['Cidades']
    }

    const response = await serverClient.get<Region>(`/Regiao/${id}`, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveRegion(data: RegionPayload, id?: number) {
  try {
    const endpoint = id ? `/Regiao/${id}` : '/Regiao'
    const request = id ? serverClient.put : serverClient.post
    const response = await request<Region>(endpoint, data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function deleteRegion(id: number) {
  try {
    await serverClient.delete(`/Regiao/${id}`)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * Interfaces
 */

export interface RegionFilters {
  description?: string
}
