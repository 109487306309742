import { action, computed, observable } from 'mobx'
import { ITechnology } from '../interfaces/ITechnology'
import { ApiError } from '../shared/ApiError'
import { tryGetTechnologies, tryGetTechnology } from '../shared/ServerApi'

export class TechnologyStore {
  // Ordenation
  @observable orderBy = 'Descricao'
  @observable orderMethod = 'Descricao_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable technologies: ITechnology[] = []

  @observable private currentSelected?: ITechnology

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions
  @action.bound async fetchTechnologies(descricao = '') {
    try {
      this.isFetching = true
      const response = await tryGetTechnologies(
        this.page,
        this.pageSize,
        this.orderMethod,
        descricao
      )

      this.total = response.totalItemCount
      this.technologies = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async selectTechnology(id: string) {
    try {
      this.hasErrorFetchingSingle = false
      this.isFetchingSingle = true
      const technology = await tryGetTechnology(id)
      this.currentSelected = technology
    } catch {
      this.hasErrorFetchingSingle = true
    } finally {
      this.isFetchingSingle = false
    }
  }

  @computed get selected() {
    return this.currentSelected
  }
}
