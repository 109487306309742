import { getCultiveTsi } from '../shared/serverApi/tsiAssociationApi'
import { CultiveTsi } from '../models/Tsi/TsiAssociation'
import { action, observable } from 'mobx'

export class TsiAssociationStore {
  @observable
  cultiveTsi = [] as CultiveTsi[]

  @observable
  isLoading = false

  @action.bound
  async loadCultiveTsi(
    safraId: string,
    cultiveId: string,
    treatmentDescription?: string
  ) {
    try {
      this.isLoading = true
      this.cultiveTsi = await getCultiveTsi(
        safraId,
        cultiveId,
        treatmentDescription
      )
    } catch (error) {
      return error
    } finally {
      this.isLoading = false
    }
  }
}
