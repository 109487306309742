import {
  IBudget,
  IBudgetIndex,
  IBudgetPayload,
  IBudgetFilters
} from '../interfaces/IBudget'
import { ApiError } from '../shared/ApiError'
import { action, observable } from 'mobx'
import {
  getBudget,
  getBudgetsIndex,
  setBudget,
  deleteBudget
} from '../shared/serverApi/budgetApi'
import { message } from 'antd'

export class BudgetStore {
  // Ordenation
  @observable orderBy = 'CreatedAt'
  @observable orderMethod = 'CreatedAt_descend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isLoading = false
  @observable error?: ApiError

  // Items
  @observable budgets: IBudgetIndex[] = []
  @observable budget?: IBudget

  //filter
  @observable filters: IBudgetFilters = {}

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method: string) {
    if (!method) this.orderMethod = this.orderBy + '_descend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  @action.bound async fetchBudgets() {
    try {
      this.error = undefined
      this.isLoading = true

      const pagination = {
        page: this.page,
        pageSize: this.pageSize
      }

      const response = await getBudgetsIndex(
        { ...this.filters, OrderBy: this.orderMethod },
        pagination
      )

      this.total = response.totalItemCount
      this.budgets = response.result
    } catch (error) {
      message.error('Erro ao carregar orçamentos')
    } finally {
      this.isLoading = false
    }
  }

  @action.bound async fetchBudget(budgetId: string) {
    try {
      this.error = undefined
      const response = await getBudget(budgetId)

      this.budget = response
    } catch (error) {
      message.error('Erro ao carregar orçamento')
    }
  }

  @action.bound
  async updateBudget(budgetId: string, payload: IBudgetPayload) {
    try {
      this.error = undefined

      await setBudget(budgetId, payload)
    } catch (error) {
      throw error
    }
  }

  @action.bound
  async deleteBudget(budgetId: string) {
    try {
      this.isLoading = true
      this.error = undefined

      await deleteBudget(budgetId)
    } catch (error) {
      throw error
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  setFilters(key: keyof IBudgetFilters, value: any) {
    this.filters = {
      ...this.filters,
      [key]: value
    }
  }
}
