import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import Helmet from 'react-helmet'
import BgOnlyLayout from './components/layout/BgOnlyLayout'
import DefaultLayout from './components/layout/DefaultLayout'
import RootRouteNode from './components/RootRouteNode'
import { useStore } from './hooks/useStore'
import './assets/styles/adjustments.css'

const App: FunctionComponent = observer(() => {
  const { isDefaultLayout, pageTitle } = useStore('layout')

  if (!isDefaultLayout)
    return (
      <div className='App'>
        <Helmet>
          <title>{pageTitle} - Agrosol Vendas</title>
        </Helmet>
        <BgOnlyLayout>
          <RootRouteNode />
        </BgOnlyLayout>
      </div>
    )

  return (
    <div className='App'>
      <Helmet>
        <title>{pageTitle} - Agrosol Vendas</title>
      </Helmet>
      <DefaultLayout>
        <RootRouteNode />
      </DefaultLayout>
    </div>
  )
})

App.displayName = 'App'

export default App
