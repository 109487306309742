import { Avatar, Col, Dropdown, Icon, Menu, Row } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router5'
import { useStore } from '../../hooks/useStore'
import NavigationBreadcrumbs from '../NavigationBreadcrumbs'
import { Panel, PanelBody } from '../Panel'

export const AuthenticatedMenu: FunctionComponent = observer(() => {
  const authStore = useStore('auth')
  const layoutStore = useStore('layout')
  const { tokenPayload, isAuth } = authStore

  if (!isAuth || !tokenPayload) return <></>

  const nameInitials = getNameInitials(tokenPayload.given_name)

  const menu = (
    <>
      <Menu>
        <Menu.Item key='profile.edit'>
          <Link routeName='profile.edit'>
            <Icon type='edit' />
            <span>Editar perfil</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='logout'>
          <Link routeName='logout'>
            <Icon type='logout' />
            <span>Sair</span>
          </Link>
        </Menu.Item>
      </Menu>
    </>
  )

  return (
    <Panel style={{ marginBottom: 0, borderRadius: 0 }}>
      <PanelBody>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <h1 style={{ marginBottom: 0 }}>{layoutStore.actualPageTitle}</h1>
            <NavigationBreadcrumbs />
          </Col>
          <Col>
            <Dropdown overlay={menu}>
              <span>
                <Avatar style={{ marginRight: '10px' }}>{nameInitials}</Avatar>
                {authStore.tokenPayload!.given_name}
              </span>
            </Dropdown>
          </Col>
        </Row>
      </PanelBody>
    </Panel>
  )
})

const getNameInitials = (name?: string) =>
  name
    ? name
        .toUpperCase()
        .split(' ')
        .reduce(
          (initials, curr) => (initials += curr.length > 2 ? curr[0] : ''),
          ''
        )
        .slice(0, 2)
    : ''
