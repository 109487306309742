import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import {
  ITsiPrice,
  IClassPrice,
  IProductPrice,
  IClassPricePayload,
  IProductPricePayload,
  ITreatmentPriceHistory
} from '../../interfaces/ITsiPrice'
import { ITsiPriceAndDiscount } from '../../interfaces/ISyngentaDiscount'
import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'
import moment from 'moment'

interface IGetClassPriceOptions {
  page?: number
  pageSize?: number
  classeProdutoId?: string
  safraId?: string
  orderBy?: string
}

interface IGetProductPriceOptions {
  page?: number
  pageSize?: number
  produtoId?: string
  safraId?: string
  orderBy?: string
}

export async function tryGetHistoryPrices(
  tratamentoId: string,
  safraId: string,
  dataLimite: string
) {
  try {
    type Response = ITreatmentPriceHistory
    const response = await serverClient.get<Response>(
      `/PrecoTratamento/PrecoConsolidado/${tratamentoId}/${safraId}/${dataLimite}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetTreatmentPrices(
  tratamentoId: string,
  safraId: string
) {
  try {
    type Response = ITsiPrice[]
    const response = await serverClient.get<Response>(
      `/PrecoTratamento/Historico/${tratamentoId}/${safraId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetClassPrices(options: IGetClassPriceOptions) {
  try {
    const params = {
      ...options
    }

    type Response = IPaginatedResponse<IClassPrice>
    const response = await serverClient.get<Response>('/PrecoTratamento', {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetProductPrices(options: IGetProductPriceOptions) {
  try {
    const params = {
      ...options
    }

    type Response = IPaginatedResponse<IProductPrice>
    const response = await serverClient.get<Response>('/PrecoTratamento', {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutClassPrice(data: IClassPricePayload) {
  try {
    await serverClient.put('/PrecoTratamento/TratamentoSafra', data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutProductPrice(data: IProductPricePayload) {
  try {
    await serverClient.put('/PrecoTratamento/TratamentoSafra', data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchTreatmentPriceByPaymentDate(
  harvest: string,
  treatment: string,
  cultive: string,
  client: number,
  paymentDate: Date
) {
  const formatedDate = moment(paymentDate).format('YYYY-MM-DD')
  const endpoint = `/PrecoTratamento/${treatment}/${harvest}/${formatedDate}`
  const params = {
    cultivarId: cultive,
    clienteId: client
  }

  try {
    const response = await serverClient.get<ITsiPriceAndDiscount>(endpoint, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}
