import { message } from 'antd'
import { action, computed, observable } from 'mobx'
import { IPeriod, IPeriodSubmitPayload } from '../interfaces/IPeriod'
import { ApiError } from '../shared/ApiError'
import {
  tryDeletePeriod,
  tryGetPeriodo,
  tryGetPeriodoAtivo,
  tryGetPeriodos,
  tryInactivatePeriod,
  tryPutPeriodo
} from '../shared/ServerApi'

export class PeriodStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  @observable
  selectedPeriod: IPeriod | undefined

  // Items
  @observable
  periods: IPeriod[] = []

  @observable
  activePeriod: IPeriod | undefined

  // States
  @observable
  isFetching = false

  @observable
  hasActivePeriod?: boolean

  @computed
  get singleItem() {
    return (id: number) => this.periods.find(element => element.id === id)
  }

  @action.bound async fetchPeriodoAtivo() {
    try {
      this.isFetching = true
      this.activePeriod = await tryGetPeriodoAtivo()
    } catch (e) {
      if (e instanceof ApiError && e.status === 404) {
        this.hasActivePeriod = false
      }
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadAll(
    nome?: string,
    harvestId?: string,
    startDate?: number,
    endDate?: number
  ) {
    try {
      this.isFetching = true
      const response = await tryGetPeriodos(
        this.page,
        this.pageSize,
        nome,
        harvestId,
        startDate,
        endDate
      )

      this.periods = response.result
      this.total = response.totalItemCount
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadSingle(id: number) {
    try {
      this.isFetching = true
      this.selectedPeriod = await tryGetPeriodo(id)
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async deleteItem(id: number) {
    try {
      this.isFetching = true
      await tryDeletePeriod(id)
      message.success('Período apagado com sucesso')
    } catch (e) {
      throw e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async inactivatePeriod() {
    try {
      this.isFetching = true
      await tryInactivatePeriod()
      message.success('Período inativado com sucesso')
    } catch (e) {
      throw e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async savePeriod(period: IPeriodSubmitPayload) {
    try {
      this.isFetching = true
      const response = await tryPutPeriodo(period)

      message.success('Período salvo com sucesso')
      return response
    } catch (e) {
      throw e
    } finally {
      this.isFetching = false
    }
  }

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method?: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }
}
