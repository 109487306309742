import { action, computed, observable, runInAction } from 'mobx'
import { ResourcesStates } from '../interfaces/ResourceStates'
import { ApiError } from '../shared/ApiError'
import {
  Envelope,
  OrderContacts,
  ContractContacts
} from '../models/Envelope/Envelope'
import {
  fetchEnvelopeDocusign,
  fetchOrderEnvelopeContacts,
  fetchContractEnvelopeContacts
} from '../shared/serverApi/envelopeApi'

export class EnvelopeStore {
  @observable
  orderEnvelope = {} as Envelope

  @observable
  contractEnvelope = {} as Envelope

  @observable
  additiveEnvelope = {} as Envelope

  @observable
  itemStates: ResourcesStates<string> = new Map()

  @observable
  orderContacts = {} as OrderContacts

  @observable orderContactsError?: ApiError

  @observable
  contractContacts = {} as ContractContacts

  @observable contractContactsError?: ApiError

  @action.bound
  async clearEnvelope(envelopeType: 'Pedido' | 'Contrato' | 'Aditivo') {
    this.itemStates = new Map()
    if (envelopeType === 'Pedido') {
      this.orderEnvelope = {} as Envelope
    } else if (envelopeType === 'Contrato') {
      this.contractEnvelope = {} as Envelope
    } else if (envelopeType === 'Aditivo') {
      this.additiveEnvelope = {} as Envelope
    }
  }

  @action.bound
  async loadEnvelope(
    id: string,
    envelopeType: 'Pedido' | 'Contrato' | 'Aditivo'
  ) {
    this.itemStates.set(id, 'Fetching')
    try {
      const item = await fetchEnvelopeDocusign(id)
      runInAction(() => {
        if (envelopeType === 'Pedido') {
          this.orderEnvelope = item
        } else if (envelopeType === 'Contrato') {
          this.contractEnvelope = item
        } else if (envelopeType === 'Aditivo') {
          this.additiveEnvelope = item
        }
        this.itemStates.set(id, 'Done')
      })
    } catch (e) {
      runInAction(() => {
        this.itemStates.set(id, 'Error')
      })
    }
  }

  @action.bound
  async loadOrderContacts() {
    try {
      const item = await fetchOrderEnvelopeContacts()
      runInAction(() => {
        this.orderContacts = item
        this.orderContactsError = undefined
      })
    } catch (e) {
      this.orderContactsError = e
    }
  }

  @action.bound
  async loadContractContacts() {
    try {
      const item = await fetchContractEnvelopeContacts()
      runInAction(() => {
        this.contractContacts = item
        this.contractContactsError = undefined
      })
    } catch (e) {
      this.orderContactsError = e
    }
  }

  @computed
  get itemState() {
    return (id: string | 'all') => this.itemStates.get(id)
  }
}
