import { Breadcrumb, Icon } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router5'
import { useStore } from '../hooks/useStore'

const NavigationBreadcrumbs: FunctionComponent = observer(() => {
  const layoutStore = useStore('layout')

  const items = layoutStore.breadcrumbs.map(i => (
    <Breadcrumb.Item key={i.title + i.routeName}>
      {i.icon ? <Icon type={i.icon} style={{ marginRight: 6 }} /> : null}
      {i.routeName ? <Link routeName={i.routeName}>{i.title}</Link> : i.title}
    </Breadcrumb.Item>
  ))

  return <Breadcrumb style={{ fontSize: 12 }}>{items}</Breadcrumb>
})

export default NavigationBreadcrumbs
