import { Expose } from 'class-transformer'

export class OrderShipping {
  id!: number

  @Expose({ name: 'pedidoId' })
  orderId!: number

  @Expose({ name: 'valorSacaNegociado' })
  valuePerSack!: number

  @Expose({ name: 'valorSacaTabela' })
  baseValuePerSack!: number
}
