import { message } from 'antd'
import { action, observable } from 'mobx'
import { IMaxDiscount, IMaxDiscountFormData } from '../interfaces/IMaxDiscount'
import { ApiError } from '../shared/ApiError'
import { tryGetMaxDiscount, tryPutMaxDiscount } from '../shared/ServerApi'

export class MaxDiscountStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable maxDiscount = {} as IMaxDiscount

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchMaxDiscount(harvestId = '') {
    try {
      this.isFetching = true
      const response = await tryGetMaxDiscount(harvestId)

      this.maxDiscount = response
    } catch (e) {
      this.maxDiscount = {} as IMaxDiscount
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.maxDiscount = {
        ...this.maxDiscount,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound
  async saveMaxDiscount(payload: IMaxDiscountFormData) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const loadingMsg = message.loading('Salvando preço do Desconto Permitido')

      await tryPutMaxDiscount(payload)
      await loadingMsg()

      message.success('Preço do desconto salvo com sucesso')

      return
    } catch (e) {
      message.error('Erro ao salvar preço do Desconto Permitido')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }
}
