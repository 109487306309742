import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'
import { IStock } from '../../interfaces/IStock'
/**
 * ----------------------------------------------------------------------------
 * Estoque do sistema de produção
 * ----------------------------------------------------------------------------
 */

export async function fetchStock(
  cultiveId = '',
  harvestId = ''
): Promise<IStock> {
  try {
    const response = await serverClient.get(
      `/Estoque/${cultiveId}/${harvestId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}
