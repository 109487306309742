import { appStores } from '../hooks/useStore'
import { AppStores } from '../createStores'

/**
 * Retorna uma store global
 */
function getStore<T extends keyof AppStores>(storeName: T): AppStores[T] {
  return appStores[storeName]
}

export default getStore
