import { message } from 'antd'
import { action, observable } from 'mobx'
import {
  IFreightPrice,
  IFreightPriceFormData
} from '../interfaces/IFreightPrice'
import { ApiError } from '../shared/ApiError'
import { tryGetFreightPrice, tryPutFreightPrice } from '../shared/ServerApi'

export class FreightPriceStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable freightPrice = {} as IFreightPrice

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchFreightPrice(regionId = '', harvestId = '') {
    try {
      this.isFetching = true
      const response = await tryGetFreightPrice(regionId, harvestId)

      this.freightPrice = response
    } catch (e) {
      this.freightPrice = {} as IFreightPrice
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.freightPrice = {
        ...this.freightPrice,
        regiaoId: regionId,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound
  async saveFreightPrice(payload: IFreightPriceFormData) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const loadingMsg = message.loading('Salvando preço do Frete')

      await tryPutFreightPrice(payload)

      await loadingMsg()
      message.success('Preço do frete salvo com sucesso')

      return /* response */
    } catch (e) {
      message.error('Erro ao salvar preço do Frete')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }
}
