import { message } from 'antd'
import { action, observable } from 'mobx'
import { IFarm, IFarmData } from '../interfaces/IFarm'
import { ApiError } from '../shared/ApiError'
import {
  tryDeleteFarm,
  getFarmsFromClient,
  tryPostFarm,
  tryPutFarm
} from '../shared/ServerApi'

export class FarmStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isLoading = false
  @observable hasError = false
  @observable error?: ApiError

  // Items
  @observable farms: IFarm[] = []

  @action.bound setPage(page: number, clientId?: number) {
    this.page = page
    if (clientId) {
      this.fetchFarms(clientId)
    }
  }

  @action.bound setPageSize(pageSize: number, clientId?: number) {
    this.pageSize = pageSize
    if (clientId) {
      this.fetchFarms(clientId)
    }
  }

  // TODO: implementar paginação
  @action.bound async fetchFarms(clientId: number) {
    try {
      this.isLoading = true
      const response = await getFarmsFromClient(
        clientId,
        this.page,
        this.pageSize
      )

      this.total = response.totalItemCount

      // Set em estadoId que não vem explicito do servidor
      this.farms = response.result.map(f => ({
        ...f,
        estadoId: f.cidade.estadoId
      }))
    } catch (e) {
      message.error('Falha ao carregar fazendas.')
      this.hasError = true
      if (e instanceof ApiError) this.error = e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveFarm(farm: IFarmData) {
    this.hasError = false
    this.error = undefined
    this.isLoading = true

    try {
      farm.id ? await tryPutFarm(farm.id, farm) : await tryPostFarm(farm)
      this.isLoading = false
      return true
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async deleteFarm(farmId: number) {
    this.isLoading = true
    this.error = undefined

    try {
      await tryDeleteFarm(farmId)
    } catch (e) {
      throw e
    } finally {
      this.isLoading = false
    }
  }
}
