import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'
import {
  ISyngentaDiscount,
  IClassDiscount
} from '../../interfaces/ISyngentaDiscount'

export async function fetchDiscount(clientId: string, safraId: string) {
  try {
    const params = { include: 'ClasseProduto' }
    const response = await serverClient.get<
      IPaginatedResponse<ISyngentaDiscount>
    >(`/DescontoTratamento?clienteId=${clientId}&safraId=${safraId}`, {
      params
    })
    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveDiscount(
  clientId: string,
  harvestId: string,
  discountTypeId: number,
  classes: IClassDiscount[]
) {
  const payload = {
    clienteId: clientId,
    safraId: harvestId,
    tipoDescontoTratamentoId: discountTypeId,
    classes: classes
  }
  try {
    await serverClient.put('/DescontoTratamento/', payload)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function deleteDiscount(discountId: number) {
  try {
    const response = await serverClient.delete(
      `/DescontoTratamento/${discountId}`
    )
    return response
  } catch (e) {
    throw createApiError(e)
  }
}
