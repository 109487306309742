import { Expose, Type } from 'class-transformer'

export class OrderInstallment {
  id!: number

  @Expose({ name: 'tipoProdutoParcela' })
  type!: OrderInstallmentType

  @Expose({ name: 'dataVencimento' })
  @Type(() => Date)
  expiresAt!: Date

  @Expose({ name: 'valor' })
  value!: number

  @Expose({ name: 'pagamentoId' })
  paymentId!: number
}

export enum OrderInstallmentType {
  Unknown = 'Nenhum',
  Seed = 'Semente',
  Royalties = 'Tecnologia',
  Treatment = 'Tratamento',
  All = 'Todos'
}
