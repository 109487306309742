import { action, observable } from 'mobx'
import { ICultive } from '../interfaces/ICultive'
import {
  tryGetCultives,
  tryGetVarredura,
  tryGetVarreduras
} from '../shared/ServerApi'
import { ApiError } from '../shared/ApiError'
import { IForecast } from '../interfaces/IForecast'

export class ForecastStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 6
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable cultives: ICultive[] = []
  @observable forecasts: IForecast[] = []
  @observable forecast?: IForecast = undefined

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions
  @action.bound setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving
  }

  @action.bound async fetchCultives(nome = '', include = ['']) {
    try {
      this.isFetching = true
      const response = await tryGetCultives({
        page: this.page,
        pageSize: this.pageSize,
        orderBy: this.orderMethod,
        include,
        nome
      })

      this.total = response.totalItemCount
      this.cultives = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  // Store actions
  @action.bound async fetchVarreduras(
    safraId: string,
    clientId: number,
    cultivarId: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetVarreduras({
        page: this.page,
        pageSize: this.pageSize,
        orderBy: this.orderMethod,
        safraId,
        clienteId: clientId,
        cultivarId
      })

      this.total = response.totalItemCount
      this.forecasts = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchVarredura(
    safraId: string,
    clientId: number,
    cultivarId: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetVarredura({
        page: 1,
        pageSize: 1,
        orderBy: this.orderMethod,
        safraId,
        clienteId: clientId,
        cultivarId
      })

      this.forecast = response ? response[0] : undefined
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }
}
