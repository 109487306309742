import * as Sentry from '@sentry/browser'
import { Button, Result } from 'antd'
import React from 'react'
import { withRouter } from 'react-router5'

interface LayoutErrorBoundaryState {
  hasError: boolean
  eventId?: string
}

class LayoutErrorBoundary extends React.Component<
  any,
  LayoutErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
    this.onClickReport = this.onClickReport.bind(this)
    this.onClickBack = this.onClickBack.bind(this)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  onClickReport() {
    Sentry.showReportDialog({ eventId: this.state.eventId })
  }

  onClickBack() {
    const router = this.props.router

    router && router.navigate('dashboard')
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status='500'
          title='500'
          subTitle='Ocorreu um erro inesperado'
          extra={
            <div>
              <Button onClick={this.onClickReport} type='primary'>
                Reportar erro
              </Button>
              <Button onClick={this.onClickBack} style={{ marginLeft: 10 }}>
                Voltar ao dashboard
              </Button>
            </div>
          }
        />
      )
    }

    return this.props.children
  }
}

export default withRouter(LayoutErrorBoundary)
