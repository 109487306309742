import { action, computed, observable, runInAction } from 'mobx'
import { IOrderInfo } from '../interfaces/IOrderInfo'
import { Pagination } from '../interfaces/Pagination'
import { ResourcesStates } from '../interfaces/ResourceStates'
import { Order } from '../models/Order/Order'
import {
  fetchOrder,
  fetchOrders,
  fetchOrderStatusInfo,
  OrderFilters
} from '../shared/serverApi/orderApi'

export class OrderStore {
  @observable requestedItems: Order[] = []

  @observable
  items = new Map<number, Order>()

  @observable
  itemInfo = {} as IOrderInfo

  @observable
  itemStates: ResourcesStates<number> = new Map()

  @observable
  filters: OrderFilters = {}

  @observable
  pagination: Pagination = {
    current: 1,
    pageSize: 15,
    total: 0
  }

  @action.bound
  async loadAll(resetItems = false) {
    this.itemStates.set('all', 'Fetching')

    try {
      const result = await fetchOrders({
        requestIncludes: true,
        filters: this.filters,
        pagination: this.pagination
      })

      this.requestedItems = result.items

      runInAction(() => {
        if (resetItems) this.items = new Map()

        result.items.forEach(item => {
          this.items.set(item.id, item)
        })

        this.pagination.total = result.pagination.total
        this.itemStates.set('all', 'Done')
      })
    } catch (e) {
      runInAction(() => {
        this.itemStates.set('all', 'Error')
      })
    }
  }

  @action.bound
  async loadSingle(id: number) {
    this.itemStates.set(id, 'Fetching')

    try {
      const item = await fetchOrder(id)
      const itemInfo = await fetchOrderStatusInfo(id)

      runInAction(() => {
        this.items.set(item.id, item)
        this.itemStates.set(id, 'Done')
        this.itemInfo = itemInfo
      })
    } catch (e) {
      runInAction(() => {
        this.itemStates.set(id, 'Error')
      })
    }
  }

  @action.bound
  setPage(page: number) {
    this.pagination.current = page
  }

  @action.bound
  setFilters(key: keyof OrderFilters, value: any) {
    this.filters = {
      ...this.filters,
      [key]: value
    }
  }

  @computed
  get singleItem() {
    return (id: number) => this.items.get(id)
  }

  @computed
  get itemState() {
    return (id: number | 'all') => this.itemStates.get(id)
  }

  @computed
  get pagedItems() {
    const pageStart = (this.pagination.current - 1) * this.pagination.pageSize
    const pageEnd = pageStart + this.pagination.pageSize

    return Array.from(this.items.values()).slice(pageStart, pageEnd)
  }
}
