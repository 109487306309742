import { AxiosError } from 'axios'
import { ApiError } from './ApiError'
import notifyAboutApiError from './notifyAboutApiError'
import notifyAboutApiErrorWithMsg from './notifyAboutApiErrorWithMsg'

/**
 * Cria um error padronizado para todas requests do server
 */
export function createApiErrorReview({ request, response }: AxiosError) {
  if (response) {
    const { title, status, errors, traceId, detail } = response.data

    if (status >= 500) notifyAboutApiError(traceId)

    if (status === 400) notifyAboutApiErrorWithMsg(errors)
    return new ApiError(title, status, {}, traceId, detail)
  }

  if (request) return new ApiError('NO_RESPONSE_ERROR', request.status, {})

  return new ApiError('ERROR', 500, {})
}
