import { message } from 'antd'
import { action, observable } from 'mobx'
import { IPms, IPmsFormData } from '../interfaces/IPms'
import { ApiError } from '../shared/ApiError'
import { tryGetPms, tryPutPms } from '../shared/ServerApi'

export class PmsStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable pms = {} as IPms

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchPms(cultiveId = '', harvestId = '') {
    try {
      this.isFetching = true
      const response = await tryGetPms(cultiveId, harvestId)

      this.pms = response
    } catch (e) {
      this.pms = {} as IPms
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.pms = {
        ...this.pms,
        cultivarId: cultiveId,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound
  async savePms(payload: IPmsFormData) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const loadingMsg = message.loading('Salvando PMS')

      await tryPutPms(payload)

      await loadingMsg()
      message.success('PMS salvo com sucesso')

      return /* response */
    } catch (e) {
      message.error('Erro ao salvar PMS')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }
}
