import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import {
  IProfessional,
  IProfessionalFormData,
  IProfessionalWithCotas
} from '../../interfaces/IProfessional'
import { createApiError } from './../createApiError'
import { serverClient } from './../serverClient'

/**
 * ----------------------------------------------------------------------------
 * Professional - api/Profissional
 * ----------------------------------------------------------------------------
 */

export interface IGetProfessionalOptions {
  usuarioId?: string
  nome?: string
  tipoProfissional?: string
  page?: number
  pageSize?: number
  orderBy?: string
  include?: string[]
  todosAtivosEInativosComVolumeSafra?: string
  todosAtivosEInativosComVendaSafra?: string
}

export interface IGetCotasOptions {
  usuarioId?: string
  nome?: string
  tipoProfissional?: string
  page?: number
  pageSize?: number
  orderBy?: string
  SafraId?: string
  CultivarId?: string
  TipoVolumeCota?: string
  include?: string[]
  todosAtivosEInativosComVolumeSafra?: string
  todosAtivosEInativosComVendaSafra?: string
}

export async function tryGetProfessionals(
  options: IGetProfessionalOptions = {}
) {
  try {
    const params = {
      page: 1,
      pageSize: 999,
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<IProfessional>>(
      '/Profissional',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetProfessionalsWithCotas(options = {}) {
  try {
    const params = {
      page: 1,
      pageSize: 20,
      ...options
    }

    const response = await serverClient.get<
      IPaginatedResponse<IProfessionalWithCotas>
    >('/RepresentanteVenda/Cotas', { params })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCotas(options: IGetCotasOptions = {}) {
  try {
    const params = {
      page: 1,
      pageSize: 20,
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<IProfessional>>(
      '/Regional/Cotas',
      { params }
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetRegions(options: IGetCotasOptions = {}) {
  try {
    const params = {
      page: 1,
      pageSize: 20,
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<IProfessional>>(
      '/Regional',
      { params }
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetRegion(id: string) {
  try {
    const response = await serverClient.get<IProfessional>(`/Regional/${id}`)
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetAlcadaAprovacaoProfessional(id: number) {
  try {
    const params = {
      ProfissionalId: id,
      include: ['Profissional']
    }

    const response = await serverClient.get('/AlcadaAprovacao/Profissional', {
      params
    })

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetProfessional(id: string) {
  try {
    const params = {
      include: 'Agente'
    }

    const response = await serverClient.get<IProfessional>(
      `/Profissional/${id}`,
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutProfessional(data: IProfessionalFormData) {
  try {
    let response

    if (!data.id) {
      response = await serverClient.post<IProfessional>('/Profissional/', data)
      if (response.data.id && data.tipoProfissional === 'AnalistaFinanceiro') {
        await serverClient.post('/AlcadaAprovacao', {
          LimiteCredito: data.limiteCredito || 0,
          ProfissionalId: response.data.id
        })
      }
    } else {
      response = await serverClient.put<IProfessional>(
        `/Profissional/${data.id}`,
        data
      )
      if (data.tipoProfissional === 'AnalistaFinanceiro') {
        if (data.alcadaAprovacaoId) {
          await serverClient.put(`/AlcadaAprovacao/${data.alcadaAprovacaoId}`, {
            LimiteCredito: data.limiteCredito,
            ProfissionalId: data.id
          })
        } else {
          await serverClient.post('/AlcadaAprovacao', {
            LimiteCredito: data.limiteCredito || 0,
            ProfissionalId: response.data.id
          })
        }
      }
    }
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeleteProfessional(id: number) {
  try {
    await serverClient.delete(`/Profissional/${id}`)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeactiveProfessional(id: number, isAtivo: boolean) {
  try {
    isAtivo
      ? await serverClient.put(`/Profissional/${id}/desativar`)
      : await serverClient.put(`/Profissional/${id}/ativar`)
  } catch (e) {
    throw createApiError(e)
  }
}
