import { ITreatment } from '../interfaces/ITreatment'
import { action, computed, observable } from 'mobx'
import { IProduct } from '../interfaces/IProduct'
import { IClass } from '../interfaces/IClass'
import { ApiError } from '../shared/ApiError'
import {
  tryGetClasses,
  tryGetProducts,
  tryGetTreatments,
  tryGetTreatment
} from '../shared/serverApi/treatmentApi'

export class TreatmentStore {
  // Ordenation
  @observable classOrderBy = 'Nome'
  @observable classOrderMethod = 'Nome_ascend'

  @observable treatmentOrderBy = 'Descricao'
  @observable treatmentOrderMethod = 'Descricao_ascend'

  @observable productOrderBy = 'ProdutoDescricao'
  @observable productOrderMethod = 'ProdutoDescricao_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable classes: IClass[] = []

  @observable treatments: ITreatment[] = []

  @observable products: IProduct[] = []

  @observable private currentSelected?: ITreatment

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setClassOrderBy(method: string) {
    if (!method) this.classOrderMethod = this.classOrderBy + '_ascend'
    else this.classOrderMethod = this.classOrderBy + '_' + method
  }

  @action.bound setTreatmentOrderBy(method: string) {
    if (!method) this.treatmentOrderMethod = this.treatmentOrderBy + '_ascend'
    else this.treatmentOrderMethod = this.treatmentOrderBy + '_' + method
  }

  @action.bound setProductOrderBy(method: string) {
    if (!method) this.productOrderMethod = this.productOrderBy + '_ascend'
    else this.productOrderMethod = this.productOrderBy + '_' + method
  }

  // Store actions
  @action.bound async fetchClasses(
    classesWithPrice?: boolean,
    className?: string,
    pageSize?: number
  ) {
    try {
      this.isFetching = true
      const response = await tryGetClasses(
        this.page,
        pageSize ? pageSize : this.pageSize,
        this.classOrderMethod,
        className,
        classesWithPrice
      )

      this.total = response.totalItemCount
      this.classes = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchProducts(productName?: string) {
    try {
      this.isFetching = true
      const response = await tryGetProducts(
        this.page,
        this.pageSize,
        this.productOrderMethod,
        productName
      )

      this.total = response.totalItemCount
      this.products = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchTreatments(treatmentName?: string) {
    try {
      this.isFetching = true
      const response = await tryGetTreatments(
        this.page,
        this.pageSize,
        this.treatmentOrderMethod,
        true,
        treatmentName
      )

      this.total = response.totalItemCount
      this.treatments = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async selectTreatment(id: string) {
    try {
      this.hasErrorFetchingSingle = false
      this.isFetchingSingle = true
      const treatment = await tryGetTreatment(id)
      this.currentSelected = treatment
    } catch {
      this.hasErrorFetchingSingle = true
    } finally {
      this.isFetchingSingle = false
    }
  }

  @computed get selected() {
    return this.currentSelected
  }
}
