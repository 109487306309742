import { message } from 'antd'
import { action, observable } from 'mobx'
import { IStock } from '../interfaces/IStock'
import { ApiError } from '../shared/ApiError'
import {
  fetchEstimate,
  saveEstimate,
  StockEstimateFormData,
  StockEstimate
} from '../shared/serverApi/estimateApi'
import { fetchStock } from '../shared/serverApi/stockApi'

export class EstimateStore {
  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable estimate = {} as StockEstimate
  @observable stock = {} as IStock

  @action.bound async loadEstimate(cultiveId = '', harvestId = '') {
    try {
      this.isFetching = true
      const response = await fetchEstimate(cultiveId, harvestId)

      this.estimate = response
    } catch (e) {
      this.estimate = {} as StockEstimate
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.estimate = {
        ...this.estimate,
        cultivarId: cultiveId,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound async loadStock(cultiveId = '', harvestId = '') {
    try {
      this.isFetching = true
      const response = await fetchStock(cultiveId, harvestId)

      this.stock = response
    } catch (e) {
      this.stock = {} as IStock
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.stock = {
        ...this.stock,
        cultivarId: cultiveId,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound
  async saveEstimate(payload: StockEstimateFormData) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const loadingMsg = message.loading('Salvando Estimativa')

      await saveEstimate(payload)

      await loadingMsg()
      message.success('Estimativa salva com sucesso')

      return
    } catch (e) {
      message.error('Erro ao salvar Estimativa')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }
}
