import { Icon, Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { Link, useRoute } from 'react-router5'
import { useStore } from '../../hooks/useStore'
import { GlobalPermissions } from '../../shared/permissions'

interface ISubMenuTitleProps {
  name: string
  icon: string
}

const SubMenuTitle: FunctionComponent<ISubMenuTitleProps> = props => (
  <>
    <Icon type={props.icon} />
    <span>{props.name}</span>
  </>
)

export const SidebarMenu = observer(() => {
  const { route } = useRoute()
  const currentOpenKey = route.name.split('.')[0]
  const { hasPerm } = useStore('auth')

  const hideFeature = (
    feature: (keyof typeof GlobalPermissions)[] | keyof typeof GlobalPermissions
  ) => {
    if (Array.isArray(feature))
      return feature.some(feat => hasPerm(feat, 'visualizar'))
        ? undefined
        : { display: 'none' }
    return !hasPerm(feature, 'visualizar') ? { display: 'none' } : undefined
  }

  return (
    <Menu
      defaultSelectedKeys={[route.name]}
      defaultOpenKeys={[currentOpenKey]}
      mode='inline'
      style={{ borderRight: -1 }}
    >
      <Menu.Item key='dashboard'>
        <Link routeName='dashboard'>
          <Icon type='home' />
          <span>Inicio</span>
        </Link>
      </Menu.Item>
      <Menu.Item style={hideFeature('cliente')} key='clients'>
        <Link routeName='clients'>
          <Icon type='user' />
          <span>Clientes</span>
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        style={
          !hasPerm('pedido', 'criar') && !hasPerm('pedido', 'visualizar')
            ? { display: 'none' }
            : undefined
        }
        title={<SubMenuTitle icon='container' name='Pedidos' />}
        key='order'
      >
        <Menu.Item
          style={
            !hasPerm('pedido', 'visualizar') ? { display: 'none' } : undefined
          }
          key='order'
        >
          <Link routeName='order'>
            <span>Gerenciar</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key='simulator'
          style={!hasPerm('pedido', 'criar') ? { display: 'none' } : undefined}
        >
          <Link routeName='order-simulator'>
            <span>Simulador</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='budget-manage' style={hideFeature('forecast')}>
          <Link routeName='budget'>
            <span>Orçamentos</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={hideFeature('forecast')}
        title={<SubMenuTitle icon='form' name='Varredura' />}
        key='forecast'
      >
        <Menu.Item style={hideFeature('forecast')} key='forecastcreate'>
          <Link routeName='forecast'>
            <span>Cadastros</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={hideFeature('period')} key='period'>
          <Link routeName='period'>
            <span>Períodos</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        title={<SubMenuTitle icon='wallet' name='Carteira' />}
        style={hideFeature('carteira')}
        key='wallet'
      >
        <Menu.Item
          key='manage-wallet'
          style={
            !hasPerm('carteira', 'gerenciar') ? { display: 'none' } : undefined
          }
        >
          <Link routeName='manage-wallet'>
            <span>Gerenciar</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key='view-wallet'
          style={
            hasPerm('carteira', 'gerenciar') ? { display: 'none' } : undefined
          }
        >
          <Link routeName='view-wallet'>
            <span>Visualizar</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={hideFeature(['regiaoFrete', 'regiaoMonsanto'])}
        title={<SubMenuTitle icon='environment' name='Regiões' />}
        key='regions'
      >
        <Menu.Item key='regions' style={hideFeature('regiaoFrete')}>
          <Link routeName='regions'>
            <span>Região Frete</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='vendor-region' style={hideFeature('regiaoMonsanto')}>
          <Link routeName='vendor-region'>
            <span>Região Monsanto</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={hideFeature('tsiAssociation')}
        title={<SubMenuTitle icon='schedule' name='Tratamento' />}
        key='tsi'
      >
        <Menu.Item style={hideFeature('tsiAssociation')} key='tsi-association'>
          <Link routeName='tsi-association'>
            <span>Associação de TSI</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={hideFeature([
          'precoDescontoCampanha',
          'precoDescontoPermitido',
          'precoFrete',
          'precoGermoplasma',
          'precoSyngentaDesconto',
          'precoTecnologia',
          'precoTratamento'
        ])}
        title={<SubMenuTitle icon='dollar' name='Preços' />}
        key='prices'
      >
        <Menu.Item style={hideFeature('precoGermoplasma')} key='germo-prices'>
          <Link routeName='germo-prices'>
            <span>Germoplasma</span>
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          title='Tratamento'
          style={hideFeature(['precoTratamento'])}
        >
          <Menu.Item
            style={hideFeature('precoTratamento')}
            key='tsi-product-prices'
          >
            <Link routeName='tsi-product-prices'>
              <span>Produtos - TSI</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            style={hideFeature('precoTratamento')}
            key='tsi-class-prices'
          >
            <Link routeName='tsi-class-prices'>
              <span>Classes - TSI</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='syngenta-classification'
            style={hideFeature('precoSyngentaClass')}
          >
            <Link routeName='syngenta-classification'>
              <span>Classificação</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='syngenta-discount'
            style={hideFeature('precoSyngentaDesconto')}
          >
            <Link routeName='syngenta-discount'>
              <span>Desconto</span>
            </Link>
          </Menu.Item>
          <Menu.Item style={hideFeature('precoTratamento')} key='tsi-prices'>
            <Link routeName='tsi-prices'>
              <span>Visualizar</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item style={hideFeature('precoTecnologia')} key='tech-prices'>
          <Link routeName='tech-prices'>
            <span>Tecnologia</span>
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          title='Desconto'
          style={hideFeature([
            'precoDescontoCampanha',
            'precoDescontoPermitido'
          ])}
        >
          <Menu.Item
            style={hideFeature('precoDescontoCampanha')}
            key='max-discount'
          >
            <Link routeName='max-discount'>
              <span>Permitido</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            style={hideFeature('precoDescontoPermitido')}
            key='seasonal-discount'
          >
            <Link routeName='seasonal-discount'>
              <span>Campanhas</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item style={hideFeature('precoFrete')} key='freight-prices'>
          <Link routeName='freight-prices'>
            <span>Frete</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={hideFeature(['medidaIR', 'medidaPMS', 'medidaPOP'])}
        title={<SubMenuTitle icon='sliders' name='Medidas' />}
        key='measure'
      >
        <Menu.Item style={hideFeature('medidaIR')} key='recomendation-rate'>
          <Link routeName='recomendation-rate'>
            <span>IR</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={hideFeature('medidaPMS')} key='pms'>
          <Link routeName='pms'>
            <span>PMS</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={hideFeature('medidaPOP')} key='pop'>
          <Link routeName='pop'>
            <span>POP</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={hideFeature(['estoqueEstimativa', 'estoqueCota'])}
        title={<SubMenuTitle icon='inbox' name='Estoque' />}
        key='stock'
      >
        <Menu.Item key='estimate' style={hideFeature('estoqueEstimativa')}>
          <Link routeName='estimate'>
            <span>Estimativa</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='quota' style={hideFeature('estoqueCota')}>
          <Link routeName='quota'>
            <span>Cotas</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key='quota-region'
          style={hideFeature('estoqueCotaRegional')}
        >
          <Link routeName='quota-region'>
            <span>Cotas Regionais</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        title={<SubMenuTitle icon='setting' name='Sistema' />}
        key='settings'
        style={hideFeature('sistemaProfissional')}
      >
        <Menu.Item
          style={hideFeature('sistemaProfissional')}
          key='professional'
        >
          <Link routeName='professional'>
            <span>Profissionais</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={hideFeature('harvest')} key='harvest'>
          <Link routeName='harvest'>
            <span>Safras</span>
          </Link>
        </Menu.Item>
        <Menu.Item style={hideFeature('barter')} key='barter'>
          <Link routeName='barter'>
            <span>Custo Barter</span>
          </Link>
        </Menu.Item>
        {/* <Menu.SubMenu title='Parametros' style={hideFeature('sistemaDocusign')}>
          <Menu.Item style={hideFeature('sistemaDocusign')} key='docusign'>
            <Link routeName='docusign'>
              <span>DocuSign</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu> */}
      </Menu.SubMenu>
    </Menu>
  )
})
