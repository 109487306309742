import { IUser, IUserFormData } from '../../interfaces/IUser'
import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'

export async function tryGetUser(id: string) {
  try {
    const response = await serverClient.get<IUser>(`/Usuario/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutUser(data: IUserFormData, profissionalId: number) {
  try {
    let response
    if (!data.usuarioId)
      response = await serverClient.post<IUser>(
        `/Usuario/${profissionalId}`,
        data
      )
    else
      response = await serverClient.put<IUser>(
        `/Usuario/${data.usuarioId}/senha`,
        data
      )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryBlockUser(id: string) {
  try {
    await serverClient.put(`/Usuario/${id}/bloquear`)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryUnblockUser(id: string) {
  try {
    await serverClient.put(`/Usuario/${id}/desbloquear`)
  } catch (e) {
    throw createApiError(e)
  }
}
