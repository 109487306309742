import { message, notification } from 'antd'
import { action, observable } from 'mobx'
import {
  IProfessional,
  IProfessionalFormData,
  IProfessionalWithCotas
} from '../interfaces/IProfessional'
import { ApiError } from '../shared/ApiError'
import {
  tryDeactiveProfessional,
  tryDeleteProfessional,
  tryGetCotas,
  tryGetProfessional,
  tryGetProfessionals,
  tryGetProfessionalsWithCotas,
  tryPutProfessional
} from '../shared/serverApi/professionalApi'

export class ProfessionalStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable professionals: IProfessional[] = []
  @observable cotasRegionais: IProfessional[] = []
  @observable professionalWithCota: IProfessionalWithCotas[] = []

  @observable professional = {} as IProfessional

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method?: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions
  @action.bound async fetchProfessionals(
    tipoProfissional?: string,
    nome?: string,
    include?: string[],
    todosAtivosEInativosComVolumeSafra?: string,
    todosAtivosEInativosComVendaSafra?: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetProfessionals({
        page: this.page,
        pageSize: this.pageSize,
        nome,
        orderBy: this.orderMethod,
        tipoProfissional,
        include,
        todosAtivosEInativosComVolumeSafra,
        todosAtivosEInativosComVendaSafra
      })

      this.total = response.totalItemCount
      this.professionals = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchProfessionalsWithCotas(
    SafraId?: string,
    CultivarId?: string,
    regionalId?: string | number,
    nome?: string,
    include?: string[]
  ) {
    try {
      this.isFetching = true
      const response = await tryGetProfessionalsWithCotas({
        page: this.page,
        pageSize: this.pageSize,
        nome,
        SafraId,
        CultivarId,
        regionalId,
        include
      })

      this.total = response.totalItemCount
      this.professionalWithCota = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchCotas(
    nome?: string,
    include?: string[],
    todosAtivosEInativosComVolumeSafra?: string,
    todosAtivosEInativosComVendaSafra?: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetCotas({
        page: this.page,
        pageSize: this.pageSize,
        nome,
        orderBy: this.orderMethod,
        include,
        todosAtivosEInativosComVolumeSafra,
        todosAtivosEInativosComVendaSafra
      })

      this.total = response.totalItemCount
      this.cotasRegionais = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchProfessional(id: string) {
    try {
      this.isFetchingSingle = true
      const response = await tryGetProfessional(id)

      this.professional = response
      this.fetchingError = undefined
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetchingSingle = false
    }
  }

  @action.bound async saveProfessional(data: IProfessionalFormData) {
    try {
      this.isSaving = true
      const response = await tryPutProfessional(data)
      message.success('Profissional salvo com sucesso.')
      this.savingError = undefined
      return response
    } catch (e) {
      if (e instanceof ApiError) this.savingError = e
      return undefined
    } finally {
      this.isSaving = false
    }
  }

  @action.bound async deleteProfessional(id: number) {
    try {
      this.isDeleting = true
      await tryDeleteProfessional(id)
      message.success('Profissional deletado com sucesso.')
    } catch (e) {
      notification.error({
        message: e.title,
        description: Object.values(e.errors),
        duration: 5
      })
      throw e
    } finally {
      this.isDeleting = false
    }
  }

  @action.bound async deactiveProfessional(id: number, isAtivo: boolean) {
    try {
      this.isDeleting = true
      await tryDeactiveProfessional(id, isAtivo)
      message.success(
        `Profissional ${isAtivo ? 'desativado' : 'ativado'} com sucesso.`
      )
    } catch (e) {
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isDeleting = false
    }
  }
}
