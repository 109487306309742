import { message } from 'antd'
import { action, observable } from 'mobx'
import {
  ISyngentaDiscount,
  IClassDiscount
} from '../interfaces/ISyngentaDiscount'
import { ApiError } from '../shared/ApiError'
import {
  fetchDiscount,
  saveDiscount,
  deleteDiscount
} from '../shared/serverApi/syngentaDiscountApi'

export class SyngentaDiscountStore {
  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable discount = [] as ISyngentaDiscount[]

  @action.bound async loadSingle(clientId: string, safraId: string) {
    try {
      this.isFetching = true
      const response = await fetchDiscount(clientId, safraId)

      if (response) {
        this.discount = response
      } else this.discount = [] as ISyngentaDiscount[]
    } catch (e) {
      this.discount = [] as ISyngentaDiscount[]
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async saveDiscount(
    clientId: string,
    harvestId: string,
    discountTypeId: number,
    classes: IClassDiscount[]
  ) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      await saveDiscount(clientId, harvestId, discountTypeId, classes)
      message.success('Desconto salvo com sucesso')

      return
    } catch (e) {
      message.error('Erro ao salvar Desconto')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }

  @action.bound
  async removeDiscount(discountId: number) {
    try {
      await deleteDiscount(discountId)
      message.success('Desconto removido com sucesso')
      return
    } catch (e) {
      message.error('Erro ao remover Desconto')
    }
  }
}
