import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import {
  IBudget,
  IBudgetIndex,
  IBudgetPayload,
  IBudgetFilters
} from '../../interfaces/IBudget'
import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'

export interface Pagination {
  page: number
  pageSize: number
}

export async function getBudgetsIndex(
  filter: IBudgetFilters,
  pagination: Pagination
) {
  const filters = {
    codigo: filter.code,
    clienteNome: filter.clientName,
    status: filter.status,
    dataInicial: filter.initialDate,
    dataFinal: filter.finalDate,
    OrderBy: filter.OrderBy
  }

  try {
    const response = await serverClient.get<IPaginatedResponse<IBudgetIndex>>(
      '/SolicitacaoOrcamento/Index',
      {
        params: {
          ...filters,
          ...pagination
        }
      }
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function getBudgets(budgetCode?: string) {
  try {
    const include = ['Itens', 'Pedidos']
    const params = { Include: include, codigo: budgetCode }
    const response = await serverClient.get<IPaginatedResponse<IBudget>>(
      '/SolicitacaoOrcamento',
      { params }
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function getBudget(budgetId: string) {
  try {
    const include = ['Itens', 'Pedidos', 'Itens.Cultivar']
    const params = { Include: include }

    const response = await serverClient.get<IBudget>(
      `/SolicitacaoOrcamento/${budgetId}`,
      { params }
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function setBudget(budgetId: string, payload: IBudgetPayload) {
  try {
    const response = await serverClient.patch(
      `/SolicitacaoOrcamento/${budgetId}`,
      payload
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function deleteBudget(budgetId: string) {
  try {
    const response = await serverClient.delete(
      `/SolicitacaoOrcamento/${budgetId}`
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}
