import { message } from 'antd'
import { action, observable } from 'mobx'
import { IGermoPrice, IGermoPriceFormData } from '../interfaces/IGermoPrice'
import { ApiError } from '../shared/ApiError'
import { tryGetGermoPrice, tryPutGermoPrice } from '../shared/ServerApi'

export class GermoPriceStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable germoPrice = {} as IGermoPrice

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchGermoPrice(
    cultiveId = '',
    harvestId = '',
    regionId: number
  ) {
    try {
      this.isFetching = true
      const response = await tryGetGermoPrice(cultiveId, harvestId, regionId)

      this.germoPrice = response
    } catch (e) {
      this.germoPrice = {} as IGermoPrice
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.germoPrice = {
        ...this.germoPrice,
        cultivarId: cultiveId,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound
  async saveGermoPrice(payload: IGermoPriceFormData) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const loadingMsg = message.loading('Salvando preço do Germoplasma')

      await tryPutGermoPrice(payload)

      await loadingMsg()
      message.success('Preço do germoplasma salvo com sucesso')

      return /* response */
    } catch (e) {
      message.error('Erro ao salvar preço do Germoplasma')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }
}
