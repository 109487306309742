import { BigNumber } from 'bignumber.js'
import { Expose, Type } from 'class-transformer'
import { oc } from 'ts-optchain'
import { useNormalizedValue } from '../../hooks/useNormalizeValue'
import { ICultive } from '../../interfaces/ICultive'
import { IPopulation } from '../../interfaces/IPopulation'
import { ISeasonalDiscount } from '../../interfaces/ISeasonalDiscount'
import { ITechPrice } from '../../interfaces/ITechPrice'
import { ITsiPriceAndDiscount } from '../../interfaces/ISyngentaDiscount'
import { ITreatment } from '../../interfaces/ITreatment'
import { ITsiPrice } from '../../interfaces/ITsiPrice'
import {
  SEEDS_PER_BAG,
  SEEDS_PER_SACK
} from '../../screens/Simulator/constants'
import { OrderSimulationSeedPricing } from './OrderSimulationSeedPricing'

export enum QuantityUnit {
  AREA = 'Area',
  SACKS = 'Sacks'
}

export enum RoundMode {
  NONE = 'None',
  UP = 'Up',
  DOWN = 'Down'
}

export class OrderItem {
  /**
   * Cálculos de quantidade
   */
  @Expose()
  recomendationRate = 0 // IR

  @Expose()
  populationRate = 0 // POP

  @Expose()
  quantityUnit = QuantityUnit.AREA

  @Expose()
  quantity = 0 // Total de bags ou área

  @Expose()
  roundMode = RoundMode.UP

  @Type(() => OrderSimulationSeedPricing)
  pricing = new OrderSimulationSeedPricing()

  /**
   * Relationships
   */
  @Expose()
  treatmentId?: string

  @Expose()
  cultiveId?: string

  // State adicional

  @Expose()
  cultive?: ICultive

  @Expose()
  treatment?: ITreatment

  @Expose()
  population?: IPopulation

  @Expose()
  treatmentPrice?: ITsiPrice

  @Expose()
  treatmentDiscount?: ITsiPriceAndDiscount

  @Expose()
  campaign?: ISeasonalDiscount

  @Expose()
  royalties?: ITechPrice

  @Expose()
  pms?: number // PMS

  @Expose()
  embarkationDate?: string

  @Expose()
  deliveryObservations?: string

  /**
   * Calcula o total de sacas para um determinado índice de recomendação
   */
  private _calculateSacks(recomendationRate: number) {
    const { populationRate, roundMode, quantity, quantityUnit } = this

    if (quantityUnit === QuantityUnit.SACKS) return quantity

    const ratio = new BigNumber(populationRate)
      .times(1000)
      .div(new BigNumber(recomendationRate).div(100))

    const isRoundModeNone = roundMode === RoundMode.NONE

    if (isRoundModeNone) {
      const result = Math.round(
        ratio
          .times(quantity)
          .div(SEEDS_PER_SACK)
          .toNumber()
      )

      return ratio.isNaN() ? 0 : result
    }

    const round = roundMode === RoundMode.UP ? Math.ceil : Math.floor
    const bagsAmount = ratio.times(quantity).div(SEEDS_PER_BAG)
    const result = new BigNumber(round(bagsAmount.toNumber()))
      .times(25)
      .toNumber()

    return useNormalizedValue(result)
  }

  /**
   * Calcula quantidade de sacas baseado nos parametros do cultivar
   */
  get calculatedSacks() {
    const { recomendationRate } = this

    return this._calculateSacks(recomendationRate)
  }

  /**
   * Calcula quantidade área possível de ser semeado baseado nos parametros do cultivar
   */
  get calculatedArea() {
    const { populationRate, recomendationRate } = this
    const quantity = new BigNumber(this._calculateSacks(recomendationRate))

    const ratio = new BigNumber(populationRate)
      .times(1000)
      .div(new BigNumber(recomendationRate).div(100))

    const result = quantity
      .times(SEEDS_PER_SACK)
      .div(ratio)
      .toNumber()

    return useNormalizedValue(result)
  }

  /**
   * Retorna quantidade de área inputada (caso quantityUnit = area) ou área calculada
   */
  get inputedArea() {
    return this.quantityUnit === QuantityUnit.AREA
      ? this.quantity
      : this.calculatedArea
  }

  private get royaltiesPricePerSack() {
    if (!this.royalties) return 0

    if (this.royalties.formaCobranca === 'Saca') return this.royalties.valor

    const populationChain = oc(this.population)
    const populationToSimulate = populationChain.valor()
      ? populationChain.valor(0)
      : this.populationRate

    const population = new BigNumber(populationToSimulate).times(1000)
    const recomendationRate = new BigNumber(85)
    const quantityPerArea = population.div(recomendationRate.div(100))

    const pricePerSeed = new BigNumber(this.royalties.valor).div(
      quantityPerArea
    )
    const total = pricePerSeed.times(SEEDS_PER_SACK).toNumber()

    return useNormalizedValue(total)
  }

  /**
   * Calcula o valor total dos Royalties
   */
  get calculatedRoyalties() {
    if (!this.population) return 0
    const total = this.royaltiesPricePerSack * this.calculatedSacks
    return useNormalizedValue(total)
  }

  /**
   * Custo de royalties por saca do pedido
   */
  get calculatedRoyaltiesPerSack() {
    if (this.royalties && this.royalties.formaCobranca === 'Saca')
      return this.royalties.valor

    const total = this.calculatedRoyalties / this.calculatedSacks
    return useNormalizedValue(total)
  }

  treatmentPriceWithClientDiscount() {
    if (!this.treatmentPrice || !this.treatmentPrice.valorSacaPadrao) return 0

    if (this.treatmentDiscount) {
      return this.treatmentDiscount.valorDescontadoSacaPadrao
    }

    return this.treatmentPrice.valorSacaPadrao
  }

  get hasError() {
    return (
      this.calculatedSacks === 0 || (!!this.treatmentId && !this.treatmentPrice)
    )
  }

  get isWaitingForRoyalty() {
    const { cultive, royalties } = this
    const hasTechnology =
      cultive && cultive.tecnologia && cultive.tecnologia.cobraRoyalties
    const hasTechnologyPrice = royalties && royalties.possuiPrecoSafra

    return hasTechnology && !hasTechnologyPrice
  }
}
