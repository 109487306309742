import { action, computed, observable } from 'mobx'

export type LayoutStyles = 'default' | 'bg-only'

export interface IBreadcrumbItem {
  title: string
  routeName?: string
  icon?: string
}

export class LayoutStore {
  @observable
  actualStyle: LayoutStyles = 'default'

  @observable
  breadcrumbs: IBreadcrumbItem[] = []

  @observable
  actualPageTitle: string = ''

  @observable isSidebarCollapsed = false

  @computed
  get isDefaultLayout() {
    return this.actualStyle === 'default'
  }

  @computed
  get pageTitle() {
    return this.actualPageTitle
  }

  /**
   * Define qual layout o app devera utilizar
   *
   * **default**: Layout com sidebar a esquerda e amplo espaço a direta
   *
   * **bg-only**: Layout com background de imagem e centralizado na tela, util para logins
   */
  @action.bound setLayoutStyle(style: LayoutStyles) {
    this.actualStyle = style
  }

  @action.bound setBreadcrumbs(items: IBreadcrumbItem[]) {
    this.breadcrumbs = items
  }
  @action.bound clearBreadcrumbs() {
    this.breadcrumbs = []
  }

  @action.bound onCollapseSidebar(isCollapsed: boolean) {
    this.isSidebarCollapsed = isCollapsed
  }

  @action.bound setPageTitle(title: string) {
    this.actualPageTitle = title
  }
}
