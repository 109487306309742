import React from 'react'
import { notification, Typography } from 'antd'

const { Text } = Typography

function notifyAboutApiError(traceId: string) {
  notification.error({
    duration: 0,
    message: 'Ocorreu um erro inesperado no servidor',
    description: (
      <>
        <p>
          Uma requisição com servidor falhou inesperadamente, um código de
          rastreamento do erro foi gerado. Entre em contato com suporte e
          informe o código a seguir.
        </p>
        <Text copyable code>
          {traceId}
        </Text>
      </>
    )
  })
}

export default notifyAboutApiError
