import { action, observable } from 'mobx'
import { IQuotaHistory } from '../interfaces/IQuotaHistory'
import { ApiError } from '../shared/ApiError'
import {
  tryGetQuotaHistory,
  tryGetRegionalQuotaHistory
} from '../shared/ServerApi'

export class QuotaHistoryStore {
  // Ordenation
  @observable orderBy = 'DateTimeCriacao_descend'

  // Pagination
  @observable page = 1
  @observable pageSize = 5
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  // Items
  @observable quotaHistory: IQuotaHistory[] = []

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Store actions
  @action.bound resetHistory() {
    this.quotaHistory = []
  }

  @action.bound async fetchQuotaHistory(
    cultivarId: string,
    safraId: string,
    directSale: boolean,
    include: string,
    representanteVendaId?: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetQuotaHistory(
        this.page,
        this.pageSize,
        cultivarId,
        safraId,
        directSale,
        include,
        this.orderBy,
        representanteVendaId
      )

      this.total = response.totalItemCount
      this.quotaHistory = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchRegionalQuotaHistory(
    cultivarId: string,
    safraId: string,
    regionalId: string,
    include: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetRegionalQuotaHistory(
        this.page,
        this.pageSize,
        cultivarId,
        safraId,
        include,
        this.orderBy,
        regionalId
      )

      this.total = response.totalItemCount
      this.quotaHistory = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }
}
