import { action, computed, observable } from 'mobx'
import { IClient, IClientData } from '../interfaces/IClient'
import { ApiError } from '../shared/ApiError'
import {
  tryDeleteClient,
  tryGetClient,
  tryGetClients,
  tryPostClient,
  tryPutClient
} from '../shared/ServerApi'

export class ClientStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isLoading = false
  @observable isSaving = false
  @observable hasError = false
  @observable error?: ApiError

  // Items
  @observable clients: IClient[] = []

  @observable selectedClient?: number

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound setError(error?: ApiError) {
    this.error = error
    this.hasError = !!error
  }

  // Ordenation actions
  @action.bound setOrderBy(method: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions
  @action.bound async fetchClient(id: number) {
    try {
      this.isLoading = true
      const response = await tryGetClient(id)
      response.estadoId = response.estado.id

      this.clients = [response]
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound async fetchClients(nome?: string, cpfCnpj?: string) {
    try {
      this.isLoading = true
      const response = await tryGetClients({
        page: this.page,
        pageSize: this.pageSize,
        nome,
        cpfCnpj,
        orderBy: this.orderMethod
      })
      this.total = response.totalItemCount

      // estadoId não vem diretamente do servidor, então para planificar a estrutura
      // setamos estadoId manualmente
      this.clients = response.result.map(c => ({ ...c, estadoId: c.estado.id }))
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveClient(client: IClientData) {
    this.hasError = false
    this.error = undefined
    this.isSaving = true

    try {
      const response = client.id
        ? await tryPutClient(client.id, client)
        : await tryPostClient(client)
      return response
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      return
    } finally {
      this.isSaving = false
    }
  }

  @action.bound
  async deleteClient(id: number) {
    this.hasError = false
    this.error = undefined
    this.isLoading = true

    try {
      await tryDeleteClient(id)
    } catch (e) {
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound setSelected(clientId: number) {
    this.selectedClient = clientId
  }

  @computed get selected() {
    const client = this.clients.find(c => {
      return c.id === this.selectedClient
    })

    return client
  }
}
