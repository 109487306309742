import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import { oc } from 'ts-optchain'
import { Pagination } from '../../interfaces/Pagination'
import { PaginatedResult } from '../../interfaces/PaginatedResult'
import { Region, RegionPayload } from '../../interfaces/Region'
import { RegionFilters } from './regionApi'

/**
 * ----------------------------------------------------------------------------
 * Tipo Regiões
 * ----------------------------------------------------------------------------
 */

export async function fetchVendorRegions(
  options: {
    filters?: RegionFilters
    pagination?: Pagination
  } = {}
): Promise<PaginatedResult<Region>> {
  try {
    const filters = {
      descricao: oc(options).filters.description()
    }

    const params = {
      page: oc(options).pagination.current(),
      pageSize: oc(options).pagination.pageSize(),
      include: ['Cidades'],
      ...filters
    }

    type Response = IPaginatedResponse<Region>
    const response = await serverClient.get<Response>('/TipoRegiao', { params })

    return {
      items: response.data.result,
      pagination: {
        current: response.data.page,
        pageSize: response.data.pageItemCount,
        total: response.data.totalItemCount
      }
    }
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchVendorRegion(id: string) {
  try {
    const params = {
      Include: ['Cidades']
    }

    const response = await serverClient.get<Region>(`/TipoRegiao/${id}`, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveRegionType(data: RegionPayload, id?: number) {
  try {
    const endpoint = id ? `/TipoRegiao/${id}` : '/TipoRegiao'
    const request = id ? serverClient.put : serverClient.post
    const response = await request<Region>(endpoint, data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function deleteVendorRegion(id: number) {
  try {
    await serverClient.delete(`/TipoRegiao/${id}`)
  } catch (e) {
    throw createApiError(e)
  }
}
