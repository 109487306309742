import { message, notification } from 'antd'
import { action, computed, observable } from 'mobx'
import {
  ISeasonalDiscount,
  ISeasonalDiscountPayload
} from '../interfaces/ISeasonalDiscount'
import { ApiError } from '../shared/ApiError'
import {
  tryGetSeasonalDiscounts,
  tryPutSeasonalDiscounts
} from '../shared/ServerApi'

export class SeasonalDiscountStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable seasonalDiscounts: ISeasonalDiscount[] = []

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchSeasonalDiscounts(
    cultiveId?: string,
    harvestId?: string,
    inProgress?: boolean
  ) {
    try {
      this.isFetching = true
      const response = await tryGetSeasonalDiscounts(
        this.page,
        this.pageSize,
        cultiveId,
        harvestId,
        inProgress
      )

      this.total = response.totalItemCount
      this.seasonalDiscounts = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async saveSeasonalDiscounts(payload: ISeasonalDiscountPayload) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const loadingMsg = message.loading('Salvando das Campanhas')
      const response = await tryPutSeasonalDiscounts(payload)

      this.isSaving = false

      await loadingMsg()
      message.success('Campanhas salvas com sucesso')

      return response
    } catch (e) {
      notification.error({
        message: 'Não foi possível salvar',
        description: Object.values(e.errors),
        duration: 5
      })
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
      return
    }
  }

  @computed.struct
  get listOfSeasonalDiscounts() {
    return this.seasonalDiscounts
  }
}
