import { Expose } from 'class-transformer'

export enum ProfessionalType {
  Unknown = 'Desconhecido',
  Agent = 'Agente',
  SalesRepresentative = 'RTV',
  Manager = 'Gerente',
  Financeiro = 'Financeiro',
  AnalistaFinanceiro = 'AnalistaFinanceiro',
  Superintendente = 'Superintendente',
  Diretoor = 'Diretor'
}

export class Professional {
  @Expose({ name: 'id' })
  id!: number

  @Expose({ name: 'nome' })
  name!: string

  @Expose({ name: 'telefone' })
  phone?: string

  @Expose()
  email?: string

  @Expose({ name: 'tipoProfissional' })
  type!: ProfessionalType

  @Expose({ name: 'usuarioId' })
  userId?: string

  @Expose({ name: 'agente' })
  agent?: Agente
}

interface Agente {
  id: number
  profissionalId: number
  representanteVendaId: number
  representanteVendaProfissionalId?: number
  descricao: string
}
