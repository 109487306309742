import { Expose, Transform } from 'class-transformer'
import { useNormalizedValue } from '../../hooks/useNormalizeValue'
import { toDate, toISO } from '../../shared/transformerHelpers'
import { OrderSimulation } from './OrderSimulation'

export enum SimulationInstallmentType {
  All = 'All',
  Seed = 'Seed',
  Treatment = 'Treatment',
  Royalties = 'Royalties'
}

export enum SimulationInstallmentMode {
  Percentage = 'Percentage',
  Manual = 'Manual'
}

export class OrderSimulationInstallment {
  @Expose()
  type = SimulationInstallmentType.All

  @Expose()
  @Transform(toISO, { toPlainOnly: true })
  @Transform(toDate, { toClassOnly: true })
  expires?: Date

  @Expose()
  unit = SimulationInstallmentMode.Percentage

  @Expose()
  value = 100

  @Expose()
  completeRemaing = false

  /**
   * Calcula o valor em reais da parcela
   */
  calculatedValue(order: OrderSimulation): number {
    if (this.unit === SimulationInstallmentMode.Manual) return this.value
    const amount = this.value / 100
    const total = this.getTotalByType(order) * amount

    return useNormalizedValue(total)
  }

  /**
   * Calcula a porcentagem da parcela em relação ao valor total
   */
  calculatedPercentage(order: OrderSimulation) {
    if (this.unit === SimulationInstallmentMode.Percentage) return this.value

    const amount = this.value / this.getTotalByType(order)
    const total = amount * 100

    return useNormalizedValue(total)
  }

  /**
   * Verifica se valor está acima do total do tipo de parcela.
   */
  isAboveMaximum(order: OrderSimulation) {
    if (this.unit === SimulationInstallmentMode.Percentage)
      return this.value > 100

    return this.value > this.getTotalByType(order)
  }

  hasErrors(order: OrderSimulation) {
    const isWaitingRoyalties = Object.values(order.items).some(
      item => item.isWaitingForRoyalty
    )
    const isRoyaltyType = this.type === SimulationInstallmentType.Royalties
    const errorZeroValue =
      this.calculatedValue(order) === 0 &&
      !(isRoyaltyType && isWaitingRoyalties)

    return (
      this.isAboveMaximum(order) || this.expires === undefined || errorZeroValue
    )
  }

  private getTotalByType(order: OrderSimulation) {
    return order.billing.getTotalByType(this.type, order)
  }
}
