import { AxiosError } from 'axios'
import { ApiError } from './ApiError'
import notifyAboutApiError from './notifyAboutApiError'

/**
 * Cria um error padronizado para todas requests do server
 */
export function createApiError({ request, response }: AxiosError) {
  if (response) {
    const { title, status, errors, traceId, detail } = response.data

    if (status >= 500) notifyAboutApiError(traceId)

    return new ApiError(title, status, errors ? errors : {}, traceId, detail)
  }

  if (request) return new ApiError('NO_RESPONSE_ERROR', request.status, {})

  return new ApiError('ERROR', 500, {})
}
