import { Expose } from 'class-transformer'

export class EnvelopeRecipients {
  @Expose()
  id!: string

  @Expose({ name: 'nome' })
  name!: string

  @Expose()
  email!: string

  @Expose()
  status!: string

  @Expose()
  cpf?: string

  @Expose({ name: 'tipoDestinatario' })
  recipientType?: string
}
