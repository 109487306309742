import { Expose, Type } from 'class-transformer'
import { OrderInstallment } from './OrderInstallment'

export class OrderPayment {
  id!: number

  @Expose({ name: 'pedidoId' })
  orderId!: number

  @Expose({ name: 'prazoGermoplasma' })
  @Type(() => Date)
  seedDeadline!: Date

  @Expose({ name: 'prazoTecnologia' })
  @Type(() => Date)
  royaltiesDeadline!: Date

  @Expose({ name: 'prazoTratamento' })
  @Type(() => Date)
  treatmentDeadline!: Date

  @Expose({ name: 'isPrazoSafra' })
  isInHarvesTerm!: boolean

  @Expose({ name: 'parcelas' })
  @Type(() => OrderInstallment)
  installments!: OrderInstallment[]
}
