import styled from 'styled-components'

const Panel = styled.div`
  background-color: #fff;
  margin-bottom: 14px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`

const PanelTitle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.3em;
  font-weight: 600;
  padding: 14px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
`

const PanelFooter = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 14px;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
`

interface IPanelBodyProps {
  padding?: number
}

const PanelBody = styled.div<IPanelBodyProps>`
  padding: ${({ padding }) =>
    padding !== undefined ? `${padding}px` : '14px'};
`

export { Panel, PanelTitle, PanelBody, PanelFooter }
