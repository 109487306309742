import { message } from 'antd'
import { action, computed, observable } from 'mobx'
import {
  ICertificadora,
  IHarvest,
  IHarvestSubmitPayload,
  IMultiplicadora
} from '../interfaces/IHarvest'
import {
  tryGetCertificadoras,
  tryGetHarvest,
  tryGetHarvests,
  tryGetMultiplicadoras,
  tryPutHarvest
} from '../shared/ServerApi'

export class HarvestStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  @observable
  selectedHarvest: IHarvest | undefined

  // Items
  @observable
  harvests: IHarvest[] = []

  @observable
  certificadoras: ICertificadora[] = []

  @observable
  multiplicadoras: IMultiplicadora[] = []
  // States
  @observable
  isFetching = false

  @computed
  get singleItem() {
    return (id: string) => this.harvests.find(element => element.id === id)
  }

  @action.bound async loadAll() {
    try {
      this.isFetching = true
      const response = await tryGetHarvests()

      this.harvests = response.result
      this.total = response.totalItemCount
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadAllCertificadoras() {
    try {
      this.isFetching = true
      const response = await tryGetCertificadoras()

      this.certificadoras = response.result
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadAllMultiplicadoras() {
    try {
      this.isFetching = true
      const response = await tryGetMultiplicadoras()

      this.multiplicadoras = response.result
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadSingle(id: string) {
    try {
      this.isFetching = true
      this.selectedHarvest = await tryGetHarvest(id)
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async saveHarvest(harvest: IHarvestSubmitPayload) {
    try {
      this.isFetching = true
      const response = await tryPutHarvest(harvest)

      message.success('Safra salva com sucesso')
      return response
    } catch (e) {
      throw e
    } finally {
      this.isFetching = false
    }
  }

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method?: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }
}
