import Axios from 'axios'
import qs from 'qs'
import { addTokenToRequest } from './addTokenToRequest'

/**
 * Instancia do axios configurado para backend agrosol-vendas-api
 */
export const serverClient = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept-Language': 'pt-BR'
  }
})

serverClient.defaults.paramsSerializer = params => qs.stringify(params)
serverClient.interceptors.request.use(addTokenToRequest)
