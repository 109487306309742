import {
  OrderItemsPlain,
  OrderItems
} from '../models/OrderSimulation/OrderSimulation'

import { plainToClass, classToPlain } from 'class-transformer'

import { OrderItem } from '../models/OrderSimulation/OrderSimulationItem'

export const toISO = (value?: Date) => (value ? value.toISOString() : undefined)
export const toDate = (value?: string) => (value ? new Date(value) : undefined)

export const toOrderItemClass = (items: OrderItemsPlain) => {
  return Object.keys(items).reduce<OrderItems>(
    (obj, key) => ({ ...obj, [key]: plainToClass(OrderItem, items[key]) }),
    {}
  )
}

export const toOrderItemsPlain = (items: OrderItems) => {
  return Object.keys(items).reduce<OrderItemsPlain>(
    (obj, key) => ({ ...obj, [key]: classToPlain(items[key]) }),
    {}
  )
}
