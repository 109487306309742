import { message } from 'antd'
import { action, computed, observable } from 'mobx'
import {
  IClassPrice,
  ITsiPrice,
  ITreatmentPriceHistory,
  IClassPricePayload,
  IProductPrice,
  IProductPricePayload
} from '../interfaces/ITsiPrice'
import { ApiError } from '../shared/ApiError'
import {
  tryPutClassPrice,
  tryPutProductPrice,
  tryGetClassPrices,
  tryGetProductPrices,
  tryGetTreatmentPrices,
  tryGetHistoryPrices
} from '../shared/serverApi/tsiPrice'

export class TsiPriceStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 99
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable classPrices: IClassPrice[] = []

  @observable productPrices: IProductPrice[] = []

  @observable treatmentPrices: ITsiPrice[] = []

  @observable
  treatmentPriceHistory: ITreatmentPriceHistory = {} as ITreatmentPriceHistory

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchClassPrices(classId?: string, harvestId?: string) {
    try {
      this.isFetching = true
      const response = await tryGetClassPrices({
        page: this.page,
        pageSize: this.pageSize,
        classeProdutoId: classId,
        safraId: harvestId,
        orderBy: 'DataLimite'
      })

      this.total = response.totalItemCount
      this.classPrices = response.result
    } catch (e) {
      this.total = 0
      this.classPrices = []
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchProductPrices(
    productId?: string,
    harvestId?: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetProductPrices({
        page: this.page,
        pageSize: this.pageSize,
        produtoId: productId,
        safraId: harvestId,
        orderBy: 'DataLimite'
      })

      this.total = response.totalItemCount
      this.productPrices = response.result
    } catch (e) {
      this.total = 0
      this.productPrices = []
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchTreatmentPrices(
    treatmentId: string,
    harvestId: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetTreatmentPrices(treatmentId, harvestId)

      this.total = response.length
      this.treatmentPrices = response
    } catch (e) {
      this.total = 0
      this.treatmentPrices = []
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchTreatmentPriceHistory(
    treatmentId: string,
    harvestId: string,
    date: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetHistoryPrices(treatmentId, harvestId, date)

      this.treatmentPriceHistory = response
    } catch (e) {
      this.treatmentPriceHistory = {} as ITreatmentPriceHistory
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async saveClassPrices(payload: IClassPricePayload) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const response = await tryPutClassPrice(payload)
      this.isSaving = false
      message.success('Preços da Classe salvos com sucesso')

      return response
    } catch (e) {
      message.error('Erro ao salvar preços da Classe')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
      return
    }
  }

  @action.bound
  async saveProductPrices(payload: IProductPricePayload) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const response = await tryPutProductPrice(payload)
      this.isSaving = false
      message.success('Preços do Produto salvos com sucesso')

      return response
    } catch (e) {
      message.error('Erro ao salvar preços do Produto')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
      return
    }
  }

  @computed.struct
  get listOfClassPrices() {
    return this.classPrices
  }

  @computed.struct
  get listOProductsPrices() {
    return this.productPrices
  }

  @computed.struct
  get listOTreatmentsPrices() {
    return this.treatmentPrices
  }
}
