import { action, observable } from 'mobx'
import { IAgent } from '../interfaces/IAgent'
import { ApiError } from '../shared/ApiError'
import { tryGetAgents } from '../shared/ServerApi'

export class AgentStore {
  // Ordenation
  @observable orderBy = 'Profissional.Nome'
  @observable orderMethod = 'Profissional.Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable agents: IAgent[] = []

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method: string) {
    if (!method) this.orderMethod = this.orderBy
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions
  @action.bound async fetchAgents(
    nome?: string,
    todosAtivosEInativosComVolumeSafra?: string
  ) {
    try {
      this.isFetching = true
      const response = await tryGetAgents({
        nome,
        page: this.page,
        pageSize: this.pageSize,
        orderBy: this.orderMethod,
        todosAtivosEInativosComVolumeSafra
      })

      this.total = response.totalItemCount
      this.agents = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }
}
