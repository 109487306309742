import { message, notification } from 'antd'
import { action, computed, observable, runInAction } from 'mobx'
import { Pagination } from '../interfaces/Pagination'
import { Region, RegionPayload } from '../interfaces/Region'
import { ResourcesStates } from '../interfaces/ResourceStates'
import {
  fetchRegion,
  fetchRegions,
  saveRegion,
  RegionFilters,
  deleteRegion
} from '../shared/serverApi/regionApi'

export class RegionStore {
  @observable
  items = new Map<number, Region>()

  @observable
  itemStates: ResourcesStates<number> = new Map()

  @observable
  filters: RegionFilters = {}

  @observable
  pagination: Pagination = {
    current: 1,
    pageSize: 15,
    total: 0
  }

  @action.bound async loadAll() {
    try {
      this.itemStates.set('all', 'Fetching')
      const result = await fetchRegions({
        pagination: this.pagination,
        filters: this.filters
      })

      runInAction(() => {
        result.items.forEach(item => {
          this.items.set(item.id, item)
        })
        this.pagination.total = result.pagination.total
        this.itemStates.set('all', 'Done')
      })
    } catch (e) {
      runInAction(() => {
        this.itemStates.set('all', 'Error')
      })
    }
  }

  @action.bound async loadSingle(id: number) {
    try {
      this.itemStates.set(id, 'Fetching')
      const vendorRegion = await fetchRegion(id)
      runInAction(() => {
        this.items.set(id, vendorRegion)
        this.itemStates.set(id, 'Done')
      })
    } catch {
      this.itemStates.set(id, 'Error')
    }
  }

  @action.bound
  async saveItem(region: RegionPayload) {
    const itemKey = region.id || 'new'
    try {
      this.itemStates.set(itemKey, 'Saving')
      const response = await saveRegion(region, region.id)

      runInAction(() => {
        this.itemStates.set(itemKey, 'Done')
        this.itemStates.set(response.id, 'Done')
        this.items.set(response.id, response)
      })

      message.success('Região salva com sucesso')
      return response
    } catch (e) {
      notification.error({
        message: 'Não foi possível salvar',
        description: Object.values(e.errors),
        duration: 5
      })
      this.itemStates.set(itemKey, 'Done')
    }

    return undefined
  }

  @action.bound
  async deleteItem(id: number) {
    try {
      const hideLoadingMsg = message.loading('Apagando Região', 0)
      this.itemStates.set(id, 'Deleting')
      await deleteRegion(id)
      hideLoadingMsg()
      message.success('Região apagada com sucesso')
      this.itemStates.delete(id)
      this.items.delete(id)
    } catch (e) {
      message.error('Não foi possível apagar Região')
      this.itemStates.set(id, 'Done')
    }
  }

  @action.bound setPage(page: number) {
    this.pagination.current = page
  }

  @action.bound
  setFilters(key: keyof RegionFilters, value: any) {
    this.filters = {
      ...this.filters,
      [key]: value
    }

    this.items = new Map()
    this.itemStates = new Map()
  }

  @computed
  get singleItem() {
    return (id: number) => this.items.get(id)
  }

  @computed
  get itemState() {
    return (id: number | 'all' | 'new') => this.itemStates.get(id)
  }

  @computed
  get pagedItems() {
    const pageStart = (this.pagination.current - 1) * this.pagination.pageSize
    const pageEnd = pageStart + this.pagination.pageSize

    return Array.from(this.items.values()).slice(pageStart, pageEnd)
  }
}
