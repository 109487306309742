import { Expose, Type } from 'class-transformer'
import { EnvelopeRecipients } from './EnvelopeRecipients'
import { EnvelopeContact } from './EnvelopeContact'

export class Envelope {
  @Expose()
  id!: string

  @Expose()
  docuSignEnvelopeId!: string

  @Expose({ name: 'pedidoId' })
  orderId!: number

  @Expose({ name: 'tipoEnvelope' })
  envelopeType!: string

  @Expose()
  status!: string

  @Expose({ name: 'destinatarios' })
  @Type(() => EnvelopeRecipients)
  recipients!: EnvelopeRecipients[]
}

export class OrderContacts {
  @Expose({ name: 'contatos' })
  contacts!: EnvelopeContact[]
}

export class ContractContacts {
  @Expose({ name: 'assinantes' })
  signers!: EnvelopeContact[]

  @Expose({ name: 'contatos' })
  contacts!: number[]
}
