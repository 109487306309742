import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { useRoute } from 'react-router5'
import styled from 'styled-components'
import { useStore } from '../../hooks/useStore'
import { AuthenticatedMenu } from './AuthenticatedMenu'
import LayoutErrorBoundary from './LayoutErrorBoundary'
import { SidebarMenu } from './SidebarMenu'

const { Sider, Content, Footer } = Layout

const Center = styled.div`
  padding: 40px 10px;
  text-align: center;
`
const Logo = styled.img`
  height: auto;
  width: 80%;
`

const CenteredFooter = styled(Footer)`
  text-align: center;
`

const DefaultLayout: FunctionComponent = observer(props => {
  const layoutStore = useStore('layout')
  const { route } = useRoute()
  const contentWidth = route.name === 'dashboard' ? undefined : 1200

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme='light'
        collapsible
        collapsed={layoutStore.isSidebarCollapsed}
        onCollapse={layoutStore.onCollapseSidebar}
        style={{ borderRight: '1px solid #e8e8e8' }}
      >
        <Center>
          <Logo src={require('../../assets/images/logo-agrosol.png')} alt='' />
        </Center>
        <SidebarMenu />
      </Sider>
      <Layout>
        <AuthenticatedMenu />
        <LayoutErrorBoundary>
          <Content
            style={{
              padding: 14,
              width: '100%',
              maxWidth: contentWidth,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            {props.children}
          </Content>
        </LayoutErrorBoundary>
        <CenteredFooter style={{ padding: 8 }}>
          <a href='http://www.sydy.com.br/'>
            <img
              style={{ width: 60, filter: 'invert(100%)' }}
              src={require('../../assets/images/logo-sydy.svg')}
              alt='Sydy Tecnologia'
            />
          </a>
        </CenteredFooter>
      </Layout>
    </Layout>
  )
})

export default DefaultLayout
