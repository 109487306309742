import { ITsiAssociation } from '../../interfaces/ITsiAssociation'
import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'

export async function getCultiveTsi(
  safraId: string,
  cultiveId: string,
  treatmentDescription?: string
) {
  try {
    const params = {
      SafraId: safraId,
      CultivarId: cultiveId,
      TratamentoDescricao: treatmentDescription,
      Include: ['Tratamento']
    }

    const response = await serverClient.get('/CultivarTratamento', { params })
    return response.data.result
  } catch (e) {
    const errorMessage = createApiError(e).errors[''][0]
    throw errorMessage
  }
}

export async function setCultiveTsi(payload: ITsiAssociation) {
  try {
    const response = await serverClient.post('/CultivarTratamento', payload)
    return response.data
  } catch (e) {
    const errorMessage = createApiError(e).errors[''][0]
    throw errorMessage
  }
}

export async function deleteCultiveTsi(cultiveTsiId: string) {
  try {
    const response = await serverClient.delete(
      `/CultivarTratamento/${cultiveTsiId}`
    )
    return response.data
  } catch (e) {
    const errorMessage = createApiError(e).errors[''][0]
    throw errorMessage
  }
}
