import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'

/**
 * ----------------------------------------------------------------------------
 * Estimativa - EstimativaComercializacao
 * ----------------------------------------------------------------------------
 */

export async function fetchEstimate(cultiveId = '', harvestId = '') {
  try {
    const response = await serverClient.get(
      `/EstimativaComercializacao/${cultiveId}/${harvestId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveEstimate(data: StockEstimateFormData) {
  try {
    if (!data.id) await serverClient.post('/EstimativaComercializacao/', data)
    else await serverClient.put(`/EstimativaComercializacao/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * Interface
 */

export interface StockEstimate {
  id: number
  quantidade: number
  safraId: string
  cultivarId: string
  isAtivo: boolean
}
export interface StockEstimateFormData {
  id?: number
  quantidade: number
  safraId: string
  cultivarId: string
  isAtivo: boolean
}

export interface StockEstimateFilters {
  cultiveId?: string
  harvestId?: string
}
