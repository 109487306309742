import { Expose } from 'class-transformer'

export class AvailableSaleVolume {
  @Expose({ name: 'volumeDisponivel' })
  value!: number

  @Expose({ name: 'cultivarId' })
  cultiveId!: string

  @Expose({ name: 'safraId' })
  harvestId!: string

  @Expose({ name: 'profissionalId' })
  professionalId!: number
}
