import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import { ITreatment } from '../../interfaces/ITreatment'
import { IProduct } from '../../interfaces/IProduct'
import { createApiError } from '../createApiError'
import { IClass } from '../../interfaces/IClass'
import { serverClient } from '../serverClient'

export async function tryGetTreatments(
  page: number,
  pageSize: number,
  orderBy: string,
  ExibirApenasTratamentosComProduto: boolean,
  query?: string,
  SafraId?: string,
  CultivarId?: string,
  ComPrecoParaSafraId?: string,
  ComPrecoParaPrazo?: Date,
  TratamentosNaoAdicionadosASafraId?: string,
  TratamentosNaoAdicionadosAoCultivarId?: string
) {
  try {
    const params = {
      orderBy,
      page,
      pageSize,
      descricao: query,
      SafraId: SafraId,
      CultivarId: CultivarId,
      ExibirApenasTratamentosComProduto: ExibirApenasTratamentosComProduto,
      ComPrecoParaSafraId,
      ComPrecoParaPrazo,
      TratamentosNaoAdicionadosASafraId,
      TratamentosNaoAdicionadosAoCultivarId
    }

    const response = await serverClient.get<IPaginatedResponse<ITreatment>>(
      '/Tratamento',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetClasses(
  page: number,
  pageSize: number,
  orderBy?: string,
  className?: string,
  PossuiPreco?: boolean
) {
  try {
    const params = {
      orderBy,
      page,
      pageSize,
      Nome: className,
      PossuiPreco: PossuiPreco
    }

    const response = await serverClient.get<IPaginatedResponse<IClass>>(
      '/ClasseProduto',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetProducts(
  page: number,
  pageSize: number,
  orderBy: string,
  productName?: string,
  SafraId?: string
) {
  try {
    const params = {
      orderBy,
      page,
      pageSize,
      Nome: productName,
      SafraId: SafraId,
      PossuiPreco: true
    }

    const response = await serverClient.get<IPaginatedResponse<IProduct>>(
      '/Produto',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetClass(id: string) {
  try {
    const response = await serverClient.get<IClass>(`/ClasseProduto/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetTreatment(id: string) {
  try {
    const response = await serverClient.get<ITreatment>(`/Tratamento/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}
