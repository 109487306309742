import { AxiosRequestConfig } from 'axios'
import getStore from './getStore'

export function addTokenToRequest(config: AxiosRequestConfig) {
  const { isAuth, token } = getStore('auth')

  if (!isAuth) return config

  config.headers.Authorization = `bearer ${token}`

  return config
}
