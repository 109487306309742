import { AuthStore } from './stores/AuthStore'
import { ClientStore } from './stores/ClientStore'
import { ContactStore } from './stores/ContactStore'
import { CultiveStore } from './stores/CultiveStore'
import { EstimateStore } from './stores/EstimateStore'
import { FarmStore } from './stores/FarmStore'
import { FreightPriceStore } from './stores/FreightPriceStore'
import { GermoPriceStore } from './stores/GermoPriceStore'
import { LayoutStore } from './stores/LayoutStore'
import { MaxDiscountStore } from './stores/MaxDiscountStore'
import { OrderStore } from './stores/OrderStore'
import { BudgetStore } from './stores/BudgetStore'
import { PmsStore } from './stores/PmsStore'
import { PopulationStore } from './stores/PopulationStore'
import { ProfessionalStore } from './stores/ProfessionalStore'
import { RecomendationRateStore } from './stores/RecomendationRateStore'
import { RegionStore } from './stores/RegionStore'
import { SeasonalDiscountStore } from './stores/SeasonalDiscountStore'
import { TechnologyStore } from './stores/TechnologyStore'
import { TechPriceStore } from './stores/TechPriceStore'
import { TreatmentStore } from './stores/TreatmentStore'
import { TsiPriceStore } from './stores/TsiPriceStore'
import { TsiAssociationStore } from './stores/TsiAssociationStore'
import { VendorRegionStore } from './stores/VendorRegionStore'
import { WalletStore } from './stores/WalletStore'
import { AgentStore } from './stores/AgentStore'
import { ForecastStore } from './stores/ForecastStore'
import { SyngentaClassificationStore } from './stores/SyngentaClassificationStore'
import { SyngentaDiscountStore } from './stores/SyngentaDiscountStore'
import { UserStore } from './stores/userStore'
import { QuotaHistoryStore } from './stores/QuotaHistoryStore'
import { PeriodStore } from './stores/PeriodStore'
import { HarvestStore } from './stores/HarvestStore'
import { EnvelopeStore } from './stores/EnvelopeStore'
import { QuotaStore } from './stores/QuotaStore'

/**
 * Lista de todas as stores da aplicação.
 */
export interface AppStores {
  layout: LayoutStore
  auth: AuthStore
  client: ClientStore
  contact: ContactStore
  farm: FarmStore
  treatment: TreatmentStore
  technology: TechnologyStore
  cultive: CultiveStore
  // Regions
  region: RegionStore
  vendorRegion: VendorRegionStore
  // Prices
  tsiPrice: TsiPriceStore
  tsiAssociation: TsiAssociationStore
  techPrice: TechPriceStore
  germoPrice: GermoPriceStore
  freightPrice: FreightPriceStore
  maxDiscount: MaxDiscountStore
  seasonalDiscount: SeasonalDiscountStore
  syngentaClassification: SyngentaClassificationStore
  syngentaDiscount: SyngentaDiscountStore
  // Metrics
  recomendationRate: RecomendationRateStore
  pms: PmsStore
  population: PopulationStore
  // Wallet
  wallet: WalletStore
  // Stock
  estimate: EstimateStore
  budget: BudgetStore
  order: OrderStore
  professional: ProfessionalStore
  agent: AgentStore
  //Period
  period: PeriodStore
  // Quota
  quota: QuotaStore
  quotaHistory: QuotaHistoryStore
  // Settings
  user: UserStore
  // Varredura
  forecast: ForecastStore
  // Safra
  harvest: HarvestStore
  // Envelope
  envelope: EnvelopeStore
}

/**
 * Instancia as stores do app
 */
function createStores(): AppStores {
  return {
    layout: new LayoutStore(),
    auth: new AuthStore(),
    client: new ClientStore(),
    contact: new ContactStore(),
    farm: new FarmStore(),
    region: new RegionStore(),
    vendorRegion: new VendorRegionStore(),
    treatment: new TreatmentStore(),
    tsiPrice: new TsiPriceStore(),
    tsiAssociation: new TsiAssociationStore(),
    technology: new TechnologyStore(),
    techPrice: new TechPriceStore(),
    cultive: new CultiveStore(),
    recomendationRate: new RecomendationRateStore(),
    germoPrice: new GermoPriceStore(),
    pms: new PmsStore(),
    population: new PopulationStore(),
    freightPrice: new FreightPriceStore(),
    period: new PeriodStore(),
    estimate: new EstimateStore(),
    maxDiscount: new MaxDiscountStore(),
    seasonalDiscount: new SeasonalDiscountStore(),
    order: new OrderStore(),
    budget: new BudgetStore(),
    envelope: new EnvelopeStore(),
    wallet: new WalletStore(),
    professional: new ProfessionalStore(),
    syngentaClassification: new SyngentaClassificationStore(),
    syngentaDiscount: new SyngentaDiscountStore(),
    forecast: new ForecastStore(),
    harvest: new HarvestStore(),
    agent: new AgentStore(),
    quotaHistory: new QuotaHistoryStore(),
    user: new UserStore(),
    quota: new QuotaStore()
  }
}

export default createStores
