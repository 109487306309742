import * as Sentry from '@sentry/browser'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/lib/locale-provider/pt_BR'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router5'
import 'reflect-metadata'
import 'typeface-open-sans'
import * as Yup from 'yup'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { configureRouter } from './shared/configureRouter'
import getStore from './shared/getStore'
import yupPtBrLocale from './shared/yupPtBrLocale'

async function initApp() {
  BigNumber.set({
    DECIMAL_PLACES: 28
  })

  if (process.env.NODE_ENV === 'production')
    Sentry.init({
      dsn: 'https://1d610b5c6b894e69b50fc9df817643fc@sentry.io/1537414'
    })

  moment.locale('pt-br')

  Yup.setLocale(yupPtBrLocale)
  const { restoreAuthentication } = getStore('auth')
  await restoreAuthentication()

  const router = configureRouter()

  router.start(() => {
    ReactDOM.render(
      <RouterProvider router={router}>
        <ConfigProvider locale={ptBR}>
          <App />
        </ConfigProvider>
      </RouterProvider>,
      document.getElementById('root')
    )
  })

  serviceWorker.unregister()
}

initApp()
