// import { message, notification } from 'antd'
import { action, observable } from 'mobx'
import { IProfessional } from '../interfaces/IProfessional'
import { ApiError } from '../shared/ApiError'
import { tryGetCotas } from '../shared/serverApi/professionalApi'

export class QuotaStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable cotasRegionais: IProfessional[] = []

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method?: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions

  @action.bound async fetchCotas(
    SafraId: string,
    CultivarId: string,
    utilizarEstimativa: boolean,
    nome?: string,
    include?: string[]
  ) {
    try {
      this.isFetching = true
      const response = await tryGetCotas({
        page: this.page,
        pageSize: this.pageSize,
        nome,
        SafraId,
        CultivarId,
        TipoVolumeCota: utilizarEstimativa ? 'Estimativa' : 'Estoque',
        orderBy: this.orderMethod,
        include
      })

      this.total = response.totalItemCount
      this.cotasRegionais = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }
}
