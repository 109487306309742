import { message, notification } from 'antd'
import { action, computed, observable } from 'mobx'
import {
  IWalletSubmitPayload,
  IWallet,
  WalletFilters
} from '../interfaces/IWallet'
import {
  tryGetWallet,
  tryGetWalletByProfessionalId,
  putWallet,
  tryDeleteWallet,
  tryGetWallets
} from '../shared/ServerApi'

export class WalletStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  @observable
  selectedWallet: IWallet | undefined

  // Items
  @observable
  wallets: IWallet[] = []

  // States
  @observable
  isFetching = false

  @observable
  filters: WalletFilters = {}

  @action.bound
  setFilters(key: keyof WalletFilters, value: any) {
    this.filters = {
      ...this.filters,
      [key]: value
    }
  }

  @computed
  get singleItem() {
    return (id: number) => this.wallets.find(element => element.id === id)
  }

  @action.bound async loadAll(profissionalId?: number) {
    try {
      this.isFetching = true
      const response = await tryGetWallets(
        this.page,
        this.pageSize,
        undefined,
        this.orderBy,
        this.filters.nome,
        profissionalId
      )

      this.wallets = response.result
      this.total = response.totalItemCount
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadSingle(id: number) {
    try {
      this.isFetching = true
      this.selectedWallet = await tryGetWallet(id)
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async loadSingleByProfessionalId(professionalId?: string) {
    try {
      this.isFetching = true
      const response = await tryGetWalletByProfessionalId(
        this.page,
        this.pageSize,
        this.orderBy,
        professionalId
      )

      this.wallets = response.result
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async saveWallet(wallet: IWalletSubmitPayload) {
    try {
      this.isFetching = true
      const response = await putWallet(wallet)

      message.success('Carteira salva com sucesso')
      return response
    } catch (e) {
      notification.error({
        message: 'Não foi possível salvar',
        description: Object.values(e.errors),
        duration: 5
      })
      throw e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async deleteItem(id: number) {
    try {
      this.isFetching = true
      await tryDeleteWallet(id)
      message.success('Carteira apagada com sucesso')
    } catch (e) {
      message.error('Não foi possível apagar carteira')
    } finally {
      this.isFetching = false
    }
  }

  // Pagination actions
  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method?: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }
}
