import { createRouter } from 'router5'
import browserPlugin from 'router5-plugin-browser'
import loggerPlugin from 'router5-plugin-logger'
import { routes } from '../routes'
import { createRoutes } from './createRoutes'

export function configureRouter() {
  const router = createRouter(createRoutes(routes), {
    defaultRoute: 'dashboard'
  })
  router.usePlugin(browserPlugin())
  router.usePlugin(loggerPlugin)
  return router
}
