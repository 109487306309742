import React from 'react'
import { notification } from 'antd'

function notifyAboutApiErrorWithMsg(errors: string[]) {
  const ErrorsArray = Object.values(errors)
  notification.error({
    duration: 3000,
    message: 'Ocorreu um erro',
    description: (
      <>
        {ErrorsArray.map(error => (
          <p key={error}>{error}</p>
        ))}
      </>
    )
  })
}

export default notifyAboutApiErrorWithMsg
