import {
  IEnvelope,
  IEnvelopeOrderContacts,
  IEnvelopeContractContacts
} from '../../interfaces/IEnvelope'
import {
  Envelope,
  OrderContacts,
  ContractContacts
} from '../../models/Envelope/Envelope'
import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'
import { plainToClass } from 'class-transformer'

export async function fetchEnvelopeDocusign(id: string) {
  try {
    const include = ['Destinatarios']
    const response = await serverClient.get(`/Envelope/${id}`, {
      params: { include }
    })
    return plainToClass(Envelope, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function sendEnvelopeDocusign(payload: IEnvelope) {
  try {
    const response = await serverClient.post('/Envelope/Enviar', payload)
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function updateEnvelopeDocusign(id: string, payload: IEnvelope) {
  try {
    const response = await serverClient.post(
      `/Envelope/AtualizarDestinatarios/${id}`,
      payload
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function cancelEnvelpeDocusign(id: string, justification: string) {
  try {
    const payload = { justificativa: justification }
    const response = await serverClient.patch(
      `/Envelope/CancelarEnvelope/${id}`,
      payload
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function setOrderEnvelopeContacts(
  payload: IEnvelopeOrderContacts
) {
  try {
    const response = await serverClient.post('/ContatoEvenlope/Pedido', payload)
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrderEnvelopeContacts() {
  try {
    const response = await serverClient.get(`/ContatoEvenlope/Pedido`)
    return plainToClass(OrderContacts, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function setContractEnvelopeContacts(
  payload: IEnvelopeContractContacts
) {
  try {
    const response = await serverClient.post(
      '/ContatoEvenlope/Contrato',
      payload
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchContractEnvelopeContacts() {
  try {
    const response = await serverClient.get(`/ContatoEvenlope/Contrato`)
    return plainToClass(ContractContacts, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}
