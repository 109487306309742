import { Expose } from 'class-transformer'
import { ICultive } from '../../interfaces/ICultive'
import { ITreatment } from '../../interfaces/ITreatment'

export class OrderItem {
  id!: number

  @Expose({ name: 'valorGermoplasmaSacaTabela' })
  baseSeedPrice!: number

  @Expose({ name: 'valorGermoplasmaSacaNegociado' })
  seedPricePerSack!: number

  @Expose({ name: 'valorTecnologiaSaca' })
  royaltiesPricePerSack!: number

  @Expose({ name: 'valorTratamentoSacaTabela' })
  baseTreatmentPrice!: number

  @Expose({ name: 'valorTratamentoSacaDescontado' })
  treatmentPricePerSack!: number

  @Expose({ name: 'descontoMaximoGermoplasma' })
  maximumSeedDiscount!: number

  @Expose({ name: 'isDescontoGermoplasmaExcedido' })
  exceededMaxSeedDiscount!: boolean

  @Expose({ name: 'isPendentePrecoTecnologia' })
  isWaitingRoyaltyPrice!: boolean

  @Expose({ name: 'quantidadeSaca' })
  totalSacks!: number

  @Expose({ name: 'pedidoId' })
  orderId!: number

  @Expose({ name: 'cultivarId' })
  cultiveId!: string

  @Expose({ name: 'cultivar' })
  cultive?: ICultive

  @Expose({ name: 'tratamentoId' })
  treatmentId?: string

  @Expose({ name: 'tratamento' })
  treatment?: ITreatment

  @Expose({ name: 'campanhaDescontoId' })
  discountCampaignId?: number

  @Expose({ name: 'populacaoEscolhida' })
  choosenPopulation!: number

  @Expose({ name: 'areaEscolhida' })
  choosenArea!: number

  @Expose({ name: 'dataEmbarque' })
  embarkationDate?: string

  @Expose({ name: 'observacoesEntrega' })
  deliveryObservations?: string

  @Expose({ name: 'irPercentualEscolhida' })
  choosenRate!: number
}
