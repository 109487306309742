import { message } from 'antd'
import { action, computed, observable } from 'mobx'
import { ITechPrice, ITechPricePayload } from '../interfaces/ITechPrice'
import { ApiError } from '../shared/ApiError'
import {
  tryGetTechnologyRoyalties,
  tryPutTechPrice,
  tryGetTechnology
} from '../shared/ServerApi'
import { ITechnology } from '../interfaces/ITechnology'

export class TechPriceStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 99
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable techPrices: ITechPrice[] = []
  @observable technology = {} as ITechnology

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchTechPrice(technologyId = '', harvestId = '') {
    try {
      this.isFetching = true
      const response = await tryGetTechnologyRoyalties({
        page: this.page,
        pageSize: this.pageSize,
        tecnologiaId: technologyId,
        safraId: harvestId,
        orderBy: 'MesReferencia'
      })

      this.total = response.totalItemCount
      this.techPrices = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async fetchTecnology(technologyId: string) {
    try {
      this.isFetching = true
      const response = await tryGetTechnology(technologyId)

      this.technology = response
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound
  async saveTechPrice(payload: ITechPricePayload) {
    try {
      this.isSaving = true
      this.hasErrorSaving = false
      this.savingError = undefined
      const loadingMsg = message.loading('Salvando Preço da Tecnologia')
      const response = await tryPutTechPrice(payload)

      this.isSaving = false

      await loadingMsg()
      message.success('Preços da Tecnologia salvo com sucesso')

      return response
    } catch (e) {
      message.error('Erro ao salvar Preço')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
      return
    } finally {
      this.isSaving = false
    }
  }

  @computed.struct
  get listOfTechPrices() {
    return this.techPrices
  }
}
