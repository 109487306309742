import { createApiError } from '../createApiError'
import { serverClient } from '../serverClient'
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import { oc } from 'ts-optchain'
import { Pagination } from '../../interfaces/Pagination'
import { PaginatedResult } from '../../interfaces/PaginatedResult'
import {
  ISyngentaClassification,
  ISyngentaClassificationPayload
} from '../../interfaces/ISyngentaClassifications'

export async function fetchSyngentaClassifications(
  options: {
    filters?: SyngentaClassificationFilters
    pagination?: Pagination
  } = {}
): Promise<PaginatedResult<ISyngentaClassification>> {
  try {
    const filters = {
      descricao: oc(options).filters.description()
    }

    const params = {
      page: oc(options).pagination.current(),
      pageSize: oc(options).pagination.pageSize(),
      ...filters
    }

    type Response = IPaginatedResponse<ISyngentaClassification>
    const response = await serverClient.get<Response>(
      '/TipoDescontoTratamento',
      { params }
    )

    return {
      items: response.data.result,
      pagination: {
        current: response.data.page,
        pageSize: response.data.pageItemCount,
        total: response.data.totalItemCount
      }
    }
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchSyngentaClassification(id: number) {
  try {
    const response = await serverClient.get<ISyngentaClassification>(
      `/TipoDescontoTratamento/${id}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveSyngentaClassification(
  data: ISyngentaClassificationPayload,
  id?: number
) {
  try {
    const endpoint = id
      ? `/TipoDescontoTratamento/${id}`
      : '/TipoDescontoTratamento'
    const request = id ? serverClient.put : serverClient.post
    const response = await request<ISyngentaClassification>(endpoint, data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function deleteSyngentaClassification(id: number) {
  try {
    await serverClient.delete(`/TipoDescontoTratamento/${id}`)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * Interfaces
 */

export interface SyngentaClassificationFilters {
  description?: string
}
