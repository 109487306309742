import { action, computed, observable } from 'mobx'
import { ICultive } from '../interfaces/ICultive'
import { ApiError } from '../shared/ApiError'
import { tryGetCultive, tryGetCultives } from '../shared/ServerApi'

export class CultiveStore {
  // Ordenation
  @observable orderBy = 'Nome'
  @observable orderMethod = 'Nome_ascend'

  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isFetchingSingle = false
  @observable hasErrorFetchingSingle = false

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  @observable isDeleting = false

  // Items
  @observable cultives: ICultive[] = []

  @observable private currentSelected?: ICultive

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  // Ordenation actions
  @action.bound setOrderBy(method: string) {
    if (!method) this.orderMethod = this.orderBy + '_ascend'
    else this.orderMethod = this.orderBy + '_' + method
  }

  // Store actions
  @action.bound async fetchCultives(obtentoraId = '', nome = '', safraId = '') {
    try {
      this.isFetching = true
      const response = await tryGetCultives({
        page: this.page,
        pageSize: this.pageSize,
        obtentoraId,
        nome,
        orderBy: this.orderMethod,
        safraId
      })

      this.total = response.totalItemCount
      this.cultives = response.result
    } catch (e) {
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isFetching = false
    }
  }

  @action.bound async selectCultive(id: string) {
    try {
      this.hasErrorFetchingSingle = false
      this.isFetchingSingle = true
      const cultive = await tryGetCultive(id)
      this.currentSelected = cultive
    } catch {
      this.hasErrorFetchingSingle = true
    } finally {
      this.isFetchingSingle = false
    }
  }

  @computed get selected() {
    return this.currentSelected
  }
}
