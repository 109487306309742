import { message } from 'antd'
import { action, observable } from 'mobx'
import {
  IRecomendationRate,
  IRecomendationRateData
} from '../interfaces/IRecomendationRate'
import { ApiError } from '../shared/ApiError'
import {
  getRecomendationRate,
  saveRecomendationRate
} from '../shared/ServerApi'

export class RecomendationRateStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isFetching = false
  @observable hasErrorFetching = false
  @observable fetchingError?: ApiError

  @observable isSaving = false
  @observable hasErrorSaving = false
  @observable savingError?: ApiError

  // Items
  @observable recomendationRate = {} as IRecomendationRate

  // Pagination actions

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async loadRecomendationRate(cultiveId = '', harvestId = '') {
    try {
      this.isFetching = true
      const response = await getRecomendationRate(cultiveId, harvestId)

      this.recomendationRate = response
    } catch (e) {
      this.recomendationRate = {} as IRecomendationRate
      this.hasErrorFetching = true
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.recomendationRate = {
        ...this.recomendationRate,
        cultivarId: cultiveId,
        safraId: harvestId
      }
      this.isFetching = false
    }
  }

  @action.bound
  async saveRecomendationRate(payload: IRecomendationRateData) {
    this.isSaving = true
    this.hasErrorSaving = false
    this.savingError = undefined

    try {
      const hideLoadingMsg = message.loading('Salvando IR')

      await saveRecomendationRate(payload)

      hideLoadingMsg()
      message.success('IR salvo com sucesso')

      return /* response */
    } catch (e) {
      message.error('Erro ao salvar IR')
      this.hasErrorSaving = true
      if (e instanceof ApiError) this.savingError = e
    } finally {
      this.isSaving = false
    }
  }
}
