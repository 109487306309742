import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import bgImage from '../../assets/images/login-bg.webp'

const BgOnlyLayout: FunctionComponent = props => {
  return <MainContainer>{props.children}</MainContainer>
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
`

export default BgOnlyLayout
