export interface IValidationErrors {
  [field: string]: string[]
}

export class ApiError extends Error {
  title: string
  status: number
  errors: IValidationErrors
  traceId?: string
  detail?: string

  constructor(
    title: string,
    status: number,
    errors: IValidationErrors,
    traceId?: string,
    detail?: string
  ) {
    super(`ApiError - ${title}`)
    this.title = title
    this.status = status
    this.errors = errors
    this.traceId = traceId
    this.detail = detail
  }
}
