import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'

export const CenteredLoading: FunctionComponent = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Spin size='large' />
  </div>
)
