import { BigNumber } from 'bignumber.js'
import { Expose } from 'class-transformer'
import { useNormalizedValue } from '../../hooks/useNormalizeValue'

export enum PricingModes {
  PERCENTAGE = 'PercentageDiscount',
  MANUAL = 'ManualPricing',
  CAMPAIGN_DISCOUNT = 'CampaignDiscount'
}

export class OrderSimulationSeedPricing {
  @Expose()
  basePrice = 0

  @Expose()
  mode = PricingModes.MANUAL

  @Expose()
  value = 0 // Valor manual ou Porcentagem de desconto

  /**
   * Preço final do germoplasma após descontos.
   */
  get calculatedPrice() {
    const { value, mode, basePrice } = this

    if (mode === PricingModes.MANUAL) return value

    // basePrice - basePrice * (value / 100)
    const ratio = new BigNumber(value).div(100).toNumber()
    const total = basePrice - basePrice * ratio
    return useNormalizedValue(total)
  }

  /**
   * Quantidade de desconto atual
   *
   * Útil para verificar se o desconto está acima
   * do máximo permitido na safra atual
   */
  get calculatedDiscountPercentage() {
    const { value, basePrice, mode } = this

    if (mode !== PricingModes.MANUAL) return value

    const variation = new BigNumber(value).minus(basePrice)
    return variation
      .div(basePrice)
      .times(100)
      .decimalPlaces(2)
      .negated()
      .toNumber()
  }
}
