import React, { FunctionComponent, Suspense } from 'react'
import { useRoute } from 'react-router5'
import { routes } from '../routes'
import { CenteredLoading } from './CenteredLoading'

const RootRouteNode: FunctionComponent = () => {
  const { route } = useRoute()

  const appRoute = routes.find(r => {
    const hasChildren = r.children !== undefined

    return hasChildren
      ? getMainRoutePath(route.name) === r.name
      : r.name === route.name
  })!

  const RouteComponent = appRoute.component!

  return (
    <Suspense fallback={<CenteredLoading />}>
      <RouteComponent />
    </Suspense>
  )
}

const getMainRoutePath = (name: string) => name.split('.')[0]

export default RootRouteNode
